import React, { useEffect, useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import TaskCard from "../components/Route/TaskCard/TaskCard";
import styles from "../styles/styles";
import { useSelector } from "react-redux";
import SpinnerX from "../components/Spinner/SpinnerX";
import { useSearchParams, Link } from "react-router-dom";

const TasksPage = () => {

  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const {allTasks, isLoading} = useSelector((state) => state.tasks);
  const { user } = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  const taskByCountry = allTasks && allTasks.filter((itmee) => itmee.targetedCountry === user?.country || itmee.targetedCountry === "Worldwide");

  useEffect(() => {
    if (categoryData === null) {
      const d = taskByCountry;
      setData(d);
    } else {
      const d =
      taskByCountry && taskByCountry.filter((i) => i.category === categoryData);
      setData(d);
    }
    //    window.scrollTo(0,0);
  }, [taskByCountry]);
   
  return (
    <>
  {
    isLoading ? (
      <SpinnerX />
    ) : (
      <div>
      <Header activeHeading={3} />
      <br />
      <br />
      {
        user && user?.role === "Worker" ? (
          <div className={`${styles.section}`}>
             <h2 className="ml-2 text-[30px] font-[600]">View other tasks</h2>

             <di className="800px:flex 800px:w-[500px] w-full block">
             <Link to="/videotasks" className="inline-block 800px:mr-4">
          <div className={`${styles.button} ! bg-[#E34234] mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Watch Videos
               </span>
          </div>
      </Link>

      <Link to="#" className="inline-block">
          <div className={`${styles.button} !bg-[teal] 800px:mt-5 800px:ml-0 ml-2`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Complete Surveys
               </span>
          </div>
      </Link>
             </di>

             <br />
             <hr />
             <br />

        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {data && data.map((i, index) => <TaskCard data={i} key={index} />)}
        </div>
        {data && data.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            No task found!
          </h1>
        ) : null}
      </div>
        ) : (
          <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
            Access Denied! Only Workers can perform task.
          </h1>
          )
      }
      <Footer />
    </div>
    )
  }
  </>
  )
};

export default TasksPage;
