import React from "react";
import styles from "../../styles/styles";
import { toast } from "react-toastify";



const Rewards = () => {

  const shownow = false;


    const handleReward = () => {
        toast.error("Not yet qualify")
    }
     
  
  return (
   <div className="!bg-[#fff] w-full">
      <div className={`${styles.section} bg-[#fff] p-4`}>
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">


        {shownow === true && (
          <>
          <div className="w-full h-[110px] bg-[#012] rounded-lg shadow-lg px-2 py-1 relative cursor-pointer"
        onClick={handleReward}
        >
          <h4 className="bg-[#fff] py-1 px-1 text-[30px] font-[600] text-[#000] justify-center items-center">
           $100 <span className="font-[400] text-[15px] text-[teal] font-[500]">Claim Now!</span>
          </h4>
          <h5 className='text-[15px] text-[#fff] px-1'>Reward for 1000 upgraded refferrers</h5>
          </div>

          <div className="w-full h-[110px] bg-[#012] rounded-lg shadow-lg px-2 py-1 relative cursor-pointer"
        onClick={handleReward}
        >
          <h4 className="bg-[#fff] py-1 px-1 text-[30px] font-[600] text-[#000] justify-center items-center">
           $2,500 <span className="font-[400] text-[15px] text-[teal] font-[500]">Claim Now!</span>
          </h4>
          <h5 className='text-[15px] text-[#fff] px-1'>Reward for 10000 upgraded refferrers</h5>
          </div>

          <div className="w-full h-[110px] bg-[#012] rounded-lg shadow-lg px-2 py-1 relative cursor-pointer"
        onClick={handleReward}
        >
          <h4 className="bg-[#fff] py-1 px-1 text-[30px] font-[600] text-[#000] justify-center items-center">
           $20,000 <span className="font-[400] text-[15px] text-[teal] font-[500]">Claim Now!</span>
          </h4>
          <h5 className='text-[15px] text-[#fff] px-1'>Reward for 100000 upgraded refferrers</h5>
          </div>

          <div className="w-full h-[110px] bg-[#012] rounded-lg shadow-lg px-2 py-1 relative cursor-pointer"
        onClick={handleReward}
        >
          <h4 className="bg-[#fff] py-1 px-1 text-[30px] font-[600] text-[#000] justify-center items-center">
           $100,000 <span className="font-[400] text-[15px] text-[teal] font-[500]">Claim Now!</span>
          </h4>
          <h5 className='text-[15px] text-[#fff] px-1'>Reward for 1000000 upgraded refferrers</h5>
          </div>
          </>
        )}

          <div className="w-full h-[110px] bg-[brown] rounded-lg shadow-lg px-2 py-1 relative cursor-pointer"
        onClick={handleReward}
        >
          <h4 className="bg-[#fff] py-1 px-1 text-[30px] font-[600] text-[#000] justify-center items-center">
           $50 <span className="font-[400] text-[15px] text-[teal] font-[500]">Claim Now!</span>
          </h4>
          <h5 className='text-[15px] text-[#fff] px-1'>Reward for reaching $1000 in affiliate commission</h5>
          </div>


          <div className="w-full h-[110px] bg-[teal] rounded-lg shadow-lg px-2 py-1 relative cursor-pointer"
        onClick={handleReward}
        >
          <h4 className="bg-[#fff] py-1 px-1 text-[30px] font-[600] text-[#000] justify-center items-center">
           $0.02 <span className="font-[400] text-[15px] text-[teal] font-[500]">Coming Soon!</span>
          </h4>
          <h5 className='text-[15px] text-[#fff] px-1'>Daily login Reward</h5>
          </div>


          <div className="w-full h-[110px] bg-[#000080] rounded-lg shadow-lg px-2 py-1 relative cursor-pointer"
        onClick={handleReward}
        >
          <h4 className="bg-[#fff] py-1 px-1 text-[30px] font-[600] text-[#000] justify-center items-center">
           $30 <span className="font-[400] text-[15px] text-[teal] font-[500]">Claim Now!</span>
          </h4>
          <h5 className='text-[15px] text-[#fff] px-1'>For successful completion of 500 Jobs</h5>
          </div>


          <div className="w-full h-[110px] bg-[#012] rounded-lg shadow-lg px-2 py-1 relative cursor-pointer"
        onClick={handleReward}
        >
          <h4 className="bg-[#fff] py-1 px-1 text-[30px] font-[600] text-[#000] justify-center items-center">
           $10 <span className="font-[400] text-[15px] text-[teal] font-[500]">Claim Now!</span>
          </h4>
          <h5 className='text-[15px] text-[#fff] px-1'>For successful completion of 1000 Tasks</h5>
          </div>


         
          

        </div>
        </div>
    </div>
    );
};

export default Rewards;