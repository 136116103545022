import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import ExtProductDetails from "../components/ExtProducts/ExtProductDetails";
import SuggestedExtProduct from "../components/ExtProducts/SuggestedExtProduct";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../server";

const ExtProductDetailsPage = () => {
  const { id } = useParams();
  const [dataTU, setDataTU] = useState(null);
  const [selectedData, setSelectedData] = useState(null); // New state for selected product

  useEffect(() => {
    axios.get(`${server}/extproduct/get-all-extproducts`, { withCredentials: true })
      .then((res) => {
        setDataTU(res.data.extproducts);
      });
  }, []);

  useEffect(() => {
    if (dataTU && id) {
      const data = dataTU.find((i) => i._id === id);
      setSelectedData(data);  // Set selected product data here
    }
  }, [dataTU, id]);  // Re-run when dataTU or id changes

  return (
    <div>
      <Header />
      {selectedData && <ExtProductDetails data={selectedData} />}
      {selectedData && <SuggestedExtProduct data={selectedData} />}
      <Footer />
    </div>
  );
};

export default ExtProductDetailsPage;
