import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import SpinnerX from "../Spinner/SpinnerX";


const TalentDetails = () => {
    const { id } = useParams();
    const [dataToUse, setDataToUse] = useState(null);
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [fpMoney, setFpMoney] = useState(0);
    
    
    useEffect(() => {
       axios
          .get(`${server}/talent/get-all-talents`, {
            withCredentials: true,
          })
          .then((res) => {
            setDataToUse(res.data.talents);
        })
          .catch((error) => {
            console.log(error.response.data.message);
           });
      }, []);

      useEffect(() => {
        const activ = user?.availableBalance;
        if(user){
          setFpMoney(activ);
        }
      }, [])
           

        const data = dataToUse && dataToUse.find((i) => i._id === id);

        const dateIs = new Date();

        const subscriptionOwner = data?.userId;
        const usersubscribingNow = user?._id;
       
           
      const subscriber = {
        subscriberName: user?.name,
        subscriberEmail: user?.email,
        subscriberId: user?._id,
        talentId:data?._id, 
        subscriptionAmount:data?.subscriptionAmount,
        subscriptionDate: dateIs,
    }

    const subAmount = data?.subscriptionAmount;
    
      
        
      const subscriptionHandler = async (e) => {
        e.preventDefault();

        if(!user){
          return toast.error("please login to continue!");
      }

      if(subscriptionOwner === usersubscribingNow){
        return toast.error("You can't subscribe to your own gig!");
    }

        if(fpMoney < subAmount){
            return toast.error("Insuficient Balance");
        }

        setIsLoading(true);

        const packageStatus = data?.packageStatus;
        const oldTS = data?.totalSubscribers;
    
        const newTS = oldTS + 1;
        
        await axios
        
              .put(
                `${server}/talent/subscription-talent-package/${id}`,
                {
                    packageStatus: packageStatus,
                    totalSubscribers: newTS,
                    subscriber: subscriber,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Subscription Successful!");
                  setIsLoading(false);
                  userPaymentBalanceForSubscriptionHandler();
                  subscriptionSaleHandler();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
                setIsLoading(false);
              });
      }


      const fullPackPurchaser = {
        fullPackPurchaserName: user?.name,
        fullPackPurchaserEmail: user?.email,
        fullPackPurchaserId: user?._id,
        talentId:data?._id, 
        packageAmount:data?.charge,
        purchaseDate: dateIs,
    }

      
      const packageAmount = data?.charge;

      const packageOna = data?.userId; 
      const userBuyingNow = user?._id;



      const fullPackHandler = async (e) => {
        e.preventDefault();

        if(!user){
          return toast.error("please login to continue!");
      }

      if(packageOna === userBuyingNow){
        return toast.error("You can't buy your own gig!");
    }

        if(fpMoney < packageAmount){
            return toast.error("Insuficient Balance");
        }

        setIsLoading(true);

        const packageStatus = data?.packageStatus;
       
       await axios
        
              .put(
                `${server}/talent/full-pack-talent-package/${id}`,
                {
                  packageStatus: packageStatus,
                  fullPackPurchaser: fullPackPurchaser,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Purchase Successful!");
                  setIsLoading(false);
                  userPaymentBalanceForFullPackPurchaseHandler();
                  fullPackSaleHandler();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
                setIsLoading(false);
              });
      }

      const userPaymentBalanceForSubscriptionHandler = async () => {

        const userId = user?._id;

        const oldBalance = user && user?.availableBalance;
        const subscriptionFee = data && data?.subscriptionAmount;
        const newBalance = Number(oldBalance) - Number(subscriptionFee);

        await axios
    
          .put(
            `${server}/user/update-user-balance-after-subscription/${userId}`,
            {
              newBalance: newBalance,
              },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("Thanks!");
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
  }


  const userPaymentBalanceForFullPackPurchaseHandler = async () => {

    const userId = user?._id;

    const oldBalance = user && user?.availableBalance;
    const fullPackAmount = data && data?.charge;
    const newBalance = Number(oldBalance) - Number(fullPackAmount);

    await axios

      .put(
        `${server}/user/update-user-balance-after-fullpack-purchase/${userId}`,
        {
          newBalance: newBalance,
          },
        { withCredentials: true }
      )
      .then((res) => {
          toast.success("Thanks!");
          })
       .catch((error) => {
        toast.error(error.response.data.message);
      });
}


  const subscriptionSaleHandler = async () => {

    const userId = data?.userId;

    const subscriptionAmount = data?.subscriptionAmount;
    
    await axios

      .put(
        `${server}/user/subscription-earning/${userId}`,
        {
          subscriptionAmount : subscriptionAmount,
          },
        { withCredentials: true }
      )
      .then((res) => {
          toast.success("You Rock!");
          navigate('/profile');
          window.location.reload();
          })
       .catch((error) => {
        toast.error(error.response.data.message);
      });
}



const fullPackSaleHandler = async () => {

  const userId = data?.userId;
  const charge = data?.charge;
 
  await axios

    .put(
      `${server}/user/fullpack-sale/${userId}`,
      {
        charge: charge,
        },
      { withCredentials: true }
    )
    .then((res) => {
        toast.success("Awesome!");
        navigate('/profile');
        window.location.reload();
        })
     .catch((error) => {
      toast.error(error.response.data.message);
    });
}
      
      return (
        <div>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
          <div className="bg-white">
            {data ? (
                <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
                <div className="w-full py-5">
                  <form onSubmit={subscriptionHandler}>
                    <div className="block w-full 800px:flex">
                        <div className="w-full 800px:w-[50%] pt-5">
                            <h4 className="pb-3 font-[600] text-[18px]">
                            {data.name}
                            </h4>

                            <h4 className="pt-5 text-[16px] text-[#000000ba] leading whitespace-pre-line" dangerouslySetInnerHTML={{ __html: data?.description }}>
                            </h4>

                            <div className="py-2 flex items-center justify-between">
                              {data.subscriptionAmount === 0 ? null : (
                                <div className="flex">
                                <h5 className={`${styles.productDiscountPrice}`}>
                               <small className="font-[400] text-[15px] text-[green]">Subscription:</small> ${data.subscriptionAmount} Monthly
                               </h5>
                               </div>
                              )}

                              {data.charge === 0 ? null : (
                                <div className="flex">
                                <h5 className={`${styles.productDiscountPrice}`}>
                               <small className="font-[400] text-[15px] text-[green]">Full Pack:</small> ${data.charge}
                               </h5>
                               </div>
                              )}
                            

                            
                           </div>
                            <br />

                            <div className="flex">
                            {data.subscriptionAmount === 0 ? null : (
                              <button
                              className={`${styles.button} !h-[42px] text-white mr-2 800px:mr-6`}
                              type="submit"
                             >
                              Subscribe
                          </button>
                            )}
                            

                            {data.charge === 0 ? null : (
                              <button
                              className={`${styles.button} !h-[42px] text-white`}
                              onClick={fullPackHandler}
                              >
                              Buy Pack
                          </button>
                            )}
                            
                           
                            </div>
                           
                            
                           </div>
                        </div>
                      </form>
                    </div>
                </div>
            ) : <div className="w-full py-5 px-10">
                <h1 className="text-[20px] text-[500] mb-3">Loading Gig Details...!</h1>
                <SpinnerX />
                </div>}
                </div>
      </>
          )}
        </div>
        );

        }

export default TalentDetails;
