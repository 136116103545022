import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { server } from "../../server";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const GoogleSignUp = () => {
  const navigate = useNavigate();

  const handleSuccess = async (response) => {
    try {
      console.log("Google response:", response);  // Log the full Google response
  
      const code = response.credential;  // The Google ID token
      const { data } = await axios.post(`${server}/user/auth/google`, { code });
  
      console.log("Backend response data:", data);  // Log the full response data to see the structure
  
      // Ensure that data is defined before trying to access 'success'
      if (data && data.token && data.success) {
        if (data.message === "User already exists. Please log in.") {
          localStorage.setItem("user_Token", data.token);  // Store JWT token securely

          // Redirect user after successful login
          toast.success("Login Successful!");
          navigate("/");  // Redirect to protected route
          window.location.reload(true);

        } else if (data.message === "User created successfully") {
          localStorage.setItem("user_Token", data.token);  // Store JWT token securely

          // Redirect user after successful login
          toast.success("Login Successful!");
          navigate("/");  // Redirect to protected route
          window.location.reload(true);
        }
      } else {
        console.error("Unexpected response structure:", data);
        alert("Unexpected response structure from backend.");
      }
    } catch (error) {
      console.error("Error during Google authentication:", error);
      alert("Failed to authenticate with Google.");
    }
  };

  const handleError = (error) => {
    console.error("Google Sign-In error:", error);
    toast.error("Google Sign-In failed.");
  };

  
  return (
    <div>
      <GoogleLogin onSuccess={handleSuccess} onError={handleError} useOneTap />
    </div>
  );
};

export default GoogleSignUp;