import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";


const TaskDetails = () => {
    const { allTasks } = useSelector((state) => state.tasks);
   const { id } = useParams();
    const [data, setData] = useState(null);
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [isTaskFinished, setIsTaskFinished] = useState(false);
    const [checkUserIdInTaskdone, setCheckUserIdInTaskdone] = useState(false);
    const [socialMediaType, setSocialMediaType] = useState("");
    const [socialMediaUsername, setSocialMediaUsername] = useState("");

    const OtherTURL = data?.targetedURL;
    const FacebookURL = data?.facebookURL;
    const TwitterURL = data?.twitterURL;
    const InstagramURL = data?.instagramURL;
    const YoutubeURL = data?.youtubeURL;
    const LinkedinURL = data?.linkedinURL;
    const TiktokURL = data?.tiktokURL;
    const GoogleURL = data?.googleURL;
    const TrustpilotURL = data?.trustpilotURL;
    const WhatsAppGURL = data?.whatsAppGroupURL;
    const TelegramURL = data?.telegramURL;
    const BoomplayURL = data?.boomPlayURL;
    const SoundCloudURL = data?.soundCloudURL;
        

    useEffect(() => {
        const data = allTasks && allTasks.find((i) => i._id === id);
        setData(data);

        const userDoingTask = user?._id;

        const isDoneTaskAlready =  data.workers.some((item) => item['workerId'] === userDoingTask );

        if(isDoneTaskAlready){
          setCheckUserIdInTaskdone(true);
        }

        const tQ = data?.taskQuantity;
        const tC = data?.totalTaskCompleted;

      if(tQ === tC){
        setIsTaskFinished(true);
      }

      }, [allTasks]);

      

    
      const worker = {
        workerName: user?.name,
        workerEmail: user?.email,
        workerId: user?._id,
        taskId:data?._id, 
        taskPayment:data?.taskPayment,
        socialMediaType: socialMediaType,
        socialMediaUsername: socialMediaUsername,
      }
      
        
      const taskUpdateHandler = async (e) => {
        e.preventDefault();

        const taskStatus = data?.taskStatus;
        const oldTC = data?.totalTaskCompleted;
    
        const newTC = oldTC + 1;
        
        await axios
        
              .put(
                `${server}/task/update-task-status/${id}`,
                {
                    taskStatus: taskStatus,
                    totalTaskCompleted: newTC,
                    worker: worker,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Job Submited!");
                  userBalanceForTaskDoneUpdateHandler();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      const userBalanceForTaskDoneUpdateHandler = async () => {

        const userId = user?._id;

        console.log(userId);

        const oldBalance = user && user?.bonusBalance;
        const newPayment = data && data?.taskPayment;
        const newBalance = Number(oldBalance) + Number(newPayment);

        const oldTE = user && user?.totalEarning;
        const newTE = Number(oldTE) + Number(newPayment);
               
    
    await axios
    
          .put(
            `${server}/user/update-user-balance-for-taskdone/${userId}`,
            {
              newBalance: newBalance,
              newTE: newTE,
              taskE:newPayment, 
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("You've Earned!");
              navigate('/tasks');
              window.location.reload();
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
  }
      
      return (
        <>
        {isTaskFinished ? 
        <div className="w-full py-5 px-10">
        <h1 className="text-[30px] text-[500]">All Tasks has been completed!!!</h1>
        <p>Please check for other available tasks by clicking the button bellow:</p>
        <Link to="/tasks" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] text-[18px]">
               View Tasks
               </span>
          </div>
        </Link>
        </div>
        : (
          <>
          {checkUserIdInTaskdone ? 
        <div className="w-full py-5 px-10">
        <h1 className="text-[30px] text-[500]">You have done this task already!</h1>
        <p>Please check for other available tasks by clicking the button bellow:</p>
        <Link to="/tasks" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] text-[18px]">
               View Tasks
               </span>
          </div>
        </Link>
        </div>
         : (
          <div className="bg-white">
            {data ? (
                <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
                <div className="w-full py-5">
                  <form onSubmit={taskUpdateHandler}>
                    <div className="block w-full 800px:flex">
                        <div className="w-full 800px:w-[50%] pt-5">
                            <h4 className="pb-3 font-[600] text-[18px]">
                            {data.name}
                            </h4>

                            <h4 className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
                            {data.description}
                            </h4>

                            {OtherTURL !== "" ? (
                              <Link to={data?.targetedURL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Target URL
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {FacebookURL !== "" ? (
                              <Link to={data?.facebookURL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Facebook
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {TwitterURL !== "" ? (
                              <Link to={data?.twitterURL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Twitter
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {InstagramURL !== "" ? (
                              <Link to={data?.instagramURL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Instagram
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {YoutubeURL !== "" ? (
                              <Link to={data?.youtubeURL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Youtube
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {LinkedinURL !== "" ? (
                              <Link to={data?.linkedinURL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Linkedin
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {TiktokURL !== "" ? (
                              <Link to={data?.tiktokURL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Tiktok
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {GoogleURL !== "" ? (
                              <Link to={data?.googleURL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Google
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}


                            {TrustpilotURL !== "" ? (
                              <Link to={data?.trustpilotURL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Trustpilot
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {WhatsAppGURL !== "" ? (
                              <Link to={data?.whatsAppGroupURL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              WhatsApp
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                              {BoomplayURL !== "" ? (
                              <Link to={data?.boomPlayURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Boomplay
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}


                          {SoundCloudURL !== "" ? (
                              <Link to={data?.soundCloudURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Soundcloud
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {TelegramURL !== "" ? (
                              <Link to={data?.telegramURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Telegram
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            <div className="py-2 flex items-center justify-between">
                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice}`}>
                            <small className="font-[400] text-[13px] text-[green]">Earning:</small> TST{data.taskPayment}
                            </h5>
              
                            </div>
                            <span className="font-[400] text-[15px] text-[green]">
                            {data.totalTaskCompleted}/{data.taskQuantity} tasks
                            </span>
                            </div>
                            <br />

                            {user && user?.role === "Worker" && (
                              <div>
                              
                              <label>Social Media Type?<span> - </span></label>
                              <select
                              name=""
                              id=""
                              onChange={(e) => setSocialMediaType(e.target.value)}
                              className="w-[200px] h-[35px] border rounded"
                              >
                              <option value="-">-</option>
                              <option value="Facebook">Facebook</option>
                              <option value="Youtube">Youtube</option>
                              <option value="Instagram">Instagram</option>
                              <option value="Tiktok">Tiktok</option>
                              <option value="Twitter">Twitter</option>
                              <option value="Telegram">Telegram</option>
                              <option value="Linkedin">Linkedin</option>
                              <option value="Spotify">Spotify</option>
                              <option value="BoomPlay">BoomPlay</option>
                              <option value="SoundCloud">SoundCloud</option>
                              <option value="GoogleReview">Google Review</option>
                              <option value="Trustpilot">Trustpilot</option>
                              <option value="WhatsApp-Group">WhatsApp Group</option>
                              <option value="Others">Others</option>
                              </select>

                              <br />
                              <br />

                              <div className="800px:flex w-full items-center">
                              <label>username: (for WhatsApp enter contact) <span> - </span></label>
                              <input
                              type="text"
                              required
                              placeholder="username"
                              value={socialMediaUsername}
                              onChange={(e) => setSocialMediaUsername(e.target.value)}
                              className="800px:w-[100px] w-[full] border 800px:mr-3 p-1 rounded"
                              />
                              </div>
                              <br/>
                                
                                <div className="800px:w-[50%] text-[16px] text-[blue]">
                                <h5>Worker: {user?.name}</h5>
                                </div> 
                                <br />
                                <h5 className="text-[18px] text-[600] text-[red] pb-4">
                                Task Submission Agreement
                                </h5>
                                <h6 className="text-[15px] pb-4">
                                By clicking the "Done" button you agree to our<span> - </span> 
                                <Link to="/task-submission-terms-and-condition" target="_blank" className="text-[18px] text-[red] pb-4">
                                terms and condition</Link> on task submission.
                                </h6>       
                                <button
                                className={`${styles.button} !h-[42px] text-white`}
                                type="submit"
                               >
                                Done
                                </button>
                              </div>
                            )}

                            
                           </div>
                        </div>
                      </form>
                    </div>
                </div>
            ) : <div className="w-full py-5 px-10">
                <h1 className="text-[30px] text-[500]">Loading Task Details...!</h1>
                <p>It may take few minutes</p>
                </div>}
            
                </div>
        )}
          </>
        )
        }
        
        </>
        );

        }

export default TaskDetails;

/*
const taskStatus = data?.taskStatus;
        const taskQty = data?.taskQuantity;
        const oldTC = data?.totalTaskCompleted;
    
        if(taskQty === oldTC){
           return toast.error("Task is Finished!");
        }
        */