import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import ExtProductCard from "../Route/ExtProductCard/ExtProductCard";
import axios from "axios";
import { server } from "../../server";

const SuggestedExtProduct = ({ data }) => {
  //const {allExtProducts} = useSelector((state) => state.extproducts);
  const [extproductData, setExtProductData] = useState();

  useEffect(() => {
    axios.get(`${server}/extproduct/get-all-extproducts`, {withCredentials: true}).then((res) => {
      setExtProductData(res.data.extproducts);
    
    })
  }, []);  
 

  return (
    <div>
      {data ? (
        <div className={`p-4 ${styles.section}`}>
          <h2
            className={`${styles.heading} text-[25px] font-[500] border-b mb-5`}
          >
            Related Product
          </h2>
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
             {
                extproductData && extproductData.map((i,index) => (
                    <ExtProductCard data={i} key={index} />
                ))
             }
      </div>
        </div>
      ) : null}
    </div>
  );
};

export default SuggestedExtProduct;
