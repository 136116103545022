import axios from "axios";
import { server } from "../../server";

// create product
export const createExtProduct = (extproductData) => async (dispatch) => {
    try {
      dispatch({
        type: "extproductCreateRequest",
      });
  
      const { data } = await axios.post(
        `${server}/extproduct/create-extproduct`,
        extproductData // Pass the product data object
      );
  
      dispatch({
        type: "extproductCreateSuccess",
        payload: data.extproduct,
      });
    } catch (error) {
      dispatch({
        type: "extproductCreateFail",
        payload: error.response?.data?.message || "Something went wrong",
      });
    }
  };

// get All ExtProducts of a shop
export const getAllExtProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllExtProductsShopRequest",
    });

    const { data } = await axios.get(
      `${server}/extproduct/get-all-extproducts-shop/${id}`
    );
    dispatch({
      type: "getAllExtProductsShopSuccess",
      payload: data.extproducts,
    });
  } catch (error) {
    dispatch({
      type: "getAllExtProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};

// delete extproduct of a shop
export const deleteExtProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteExtProductRequest",
    });

    const { data } = await axios.delete(
      `${server}/extproduct/delete-shop-extproduct/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteExtProductSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteExtProductFailed",
      payload: error.response.data.message,
    });
  }
};

// get all extproducts
export const getAllExtProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllExtProductsRequest",
    });

    const { data } = await axios.get(`${server}/extproduct/get-all-extproducts`);
    dispatch({
      type: "getAllExtProductsSuccess",
      payload: data.extproducts,
    });
  } catch (error) {
    dispatch({
      type: "getAllExtProductsFailed",
      payload: error.response.data.message,
    });
  }
};
