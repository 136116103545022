import React, { useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import Spinner from "../components/Spinner/Spinner";
import EventAdCard from "../components/Route/EventAdCard/EventAdCard";
import styles from "../styles/styles";
import { server } from "../server";
import axios from "axios";
import SpinnerFull from "../components/Spinner/SpinnerFull";

const EventAdPage = () => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    axios.get(`${server}/eventAd/get-all-eventAds`, {withCredentials: true}).then((res) => {
     setData(res.data.eventAds);
  })
 }, []); 


  
  return (
  <>
  <div>
      <Header activeHeading={5}/>
      <br />
      <br />

      
      <div className={`${styles.section} bg-[#fff] p-4`}>
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {data && data.map((i, index) => <EventAdCard data={i} key={index} />)}
        </div>
        {data && data.length === 0 ? (
          <div className="w-full py-5 px-10">
          <h1 className="text-[20px] text-[500] mb-3">Loading Events...!</h1>
          <SpinnerFull />
          </div>
        ) : null}
      </div>

      </div>
  </>
  );
};

export default EventAdPage;
