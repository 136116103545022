import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header2 from "../components/Layout/Header2";
//import Spinner from "../components/Spinner/Spinner";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import ExtProductCard from "../components/Route/ExtProductCard/ExtProductCard";
import styles from "../styles/styles";
import { categoriesData } from "../static/data";
import axios from "axios";
import { server } from "../server";


const ProductsPage = () => {
  const {allProducts} = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  //const [data3, setData3] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [categorry, setCategorry] = useState("");
  const [active, setActive] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [extP, setExtP] = useState([]);


  useEffect(() => {
    axios.get(`${server}/extproduct/get-all-extproducts`, {withCredentials: true}).then((res) => {
      setExtP(res.data.extproducts);
    })
  }, []);  
  
  
  useEffect(() => {
    const d =
      allProducts && allProducts.filter((i) => i.category !== "Course");
      setData(d);   
  }, [allProducts]);
   
  console.log("extP is:");
  console.log(extP);

  const searchbyCategoryHandler = () => {
    const sbyC = data && data.filter((i) => i.category === categorry);
    setData(sbyC);
  }  

  const searchbyCategoryHandler2 = () => {
    const sbyC2 = extP && extP.filter((i) => i.category === categorry);
    setExtP(sbyC2);
  }  

  return (
  <div>
      <Header2 activeHeading={2} />
      <br />
      <div className="w-full flex px-9 pt-2 pb-2">      
          <div className="relative">
            <h5
              className={
                `${
                  active === 1 ? "text-[teal]" : "text-[#000]"
                } text-[15px] leading-5 font-[500] cursor-pointer 800px:text-[16px]`
              }
              onClick={() => setActive(1)}
            >
              External Products
            </h5>
            {active === 1 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>

          <div className="relative">
            <h5
              className={
                `${
                  active === 2 ? "text-[teal]" : "text-[#000]"
                } text-[15px] ml-4 leading-5 font-[500] cursor-pointer 800px:text-[16px]`
              }
              onClick={() => setActive(2)}
            >
              Internal Products
            </h5>
            {active === 2 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>

          <div className="relative">
            <h5
              className={
                `${
                  active === 2 ? "text-[teal]" : "text-[#000]"
                } text-[15px] ml-4 leading-5 font-[500] cursor-pointer 800px:text-[16px]`
              }
              onClick={() => setActive(3)}
            >
              CPA Offers
            </h5>
            {active === 3 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
      </div>

      <br />
      <br />
      

      {active === 1 ? (
        <div className={`${styles.section} bg-[#fff] p-4`}>

        <div>
              <h1 className="font-[500]">Search/Filter by:</h1>
            </div>
  
        <div className="mb-4 block 800px:flex">
            
          <div className="flex 800px:ml-4">
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={categorry}
              onChange={(e) => setCategorry(e.target.value)}
            >
              <option value="Select">Category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
  
            <div className={`${styles.button} !rounded-[5px] !w-[70px] !h-[30px] mb-5 ml-1`}
            onClick={searchbyCategoryHandler2}
            >
            <span className="text-[#fff] font-[Poppins] text-[14px]">
              Search
            </span>
          </div>
          </div>
          
  
          
            </div>
  
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {extP && extP.map((i, index) => <ExtProductCard data={i} key={index} />)}
          </div>
          {extP && extP.length === 0 ? (
            <h1 className="text-center w-full pb-[100px] text-[20px]">
              fetching products...!
            </h1>
          ) : null}
        </div>
      ) : null};


      {active === 2 ? (
        <div className={`${styles.section} bg-[#fff] p-4`}>

        <div>
              <h1 className="font-[500]">Search/Filter by:</h1>
            </div>
  
        <div className="mb-4 block 800px:flex">
            
          <div className="flex 800px:ml-4">
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={categorry}
              onChange={(e) => setCategorry(e.target.value)}
            >
              <option value="Select">Category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
  
            <div className={`${styles.button} !rounded-[5px] !w-[70px] !h-[30px] mb-5 ml-1`}
            onClick={searchbyCategoryHandler}
            >
            <span className="text-[#fff] font-[Poppins] text-[14px]">
              Search
            </span>
          </div>
          </div>
          
  
          
            </div>
  
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {data && data.map((i, index) => <ProductCard data={i} key={index} user={user} />)}
          </div>
          {data && data.length === 0 ? (
            <h1 className="text-center w-full pb-[100px] text-[20px]">
             No External products found...!
            </h1>
          ) : null}
        </div>
      ) : null};

      {active === 3 ? (
        <div className={`${styles.section} bg-[#fff] p-4`}>

        <div>
              <h1 className="font-[500]">Search/Filter by:</h1>
            </div>
  
        <div className="mb-4 block 800px:flex">
            
          <div className="flex 800px:ml-4">
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={categorry}
              onChange={(e) => setCategorry(e.target.value)}
            >
              <option value="Select">Category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
  
            <div className={`${styles.button} !rounded-[5px] !w-[70px] !h-[30px] mb-5 ml-1`}
            onClick={searchbyCategoryHandler}
            >
            <span className="text-[#fff] font-[Poppins] text-[14px]">
              Search
            </span>
          </div>
          </div>
          
  
          
            </div>
  
        
            <h1 className="text-center w-full pb-[100px] text-[20px]">
              No offer found...!
            </h1>
         
        </div>
      ) : null};

    </div>
  
  );
};

export default ProductsPage;
