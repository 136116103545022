import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const DataDeletion = () => {
  return (
    <div className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}>
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <h1 className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize mt-4`}>
          Data Deletion Request
        </h1>
        <h5 className="text-[18px] font-[500]">Last Updated: January 23, 2025</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          We respect your privacy and understand that there may be situations where you wish to delete your personal data from our platform.
          By submitting a data deletion request, you are requesting that we permanently remove all personal information associated with your account.
        </p>
        <br />
        <h5 className="text-[18px] font-[500]">1. How to Request Data Deletion</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          If you would like to delete your personal data from our platform, please follow these steps:
          <br />
          * Log in to your account on Totlesoft.
          <br />
          * Go to the "Account Settings" section.
          <br />
          * Select the option "Request Data Deletion".
          <br />
          * Confirm your request to permanently delete all personal information associated with your account.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">2. What Happens After a Data Deletion Request?</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          Upon receiving your data deletion request, we will begin processing the removal of your data from our systems. Please note that this process may take up to 30 days to complete.
          Once your data is deleted, you will no longer have access to any past account information, transaction history, or any data associated with your profile.
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          We advise you to download any important data before proceeding with the deletion request, as it cannot be recovered after deletion.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">3. Exceptions</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          In some cases, we may retain certain information for legal, regulatory, or operational purposes. This may include:
          <br />
          * Compliance with legal obligations.
          <br />
          * Prevention of fraud or abuse.
          <br />
          * Resolution of disputes.
          <br />
          This information will be retained only for as long as necessary and will be securely deleted once no longer required.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">4. Contact Information</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          If you have any questions about the data deletion process or would like to make a request, please contact us at:
          <br />
          * Email: support@totlesoft.com
          <br />
          * Website: https://totlesoft.com
        </p>

        <Link to="/" className="inline-block">
          <div className={`${styles.button} mt-5`}>
            <span className="text-[#fff] font-[Poppins] !text-[14px]">
              Home page
            </span>
          </div><br /><br />
        </Link>
      </div>
    </div>
  );
};

const DataDeletionPage = () => {
  return (
    <div>
      <Header activeHeading={6} />
      <DataDeletion />
      <Footer />
    </div>
  );
};

export default DataDeletionPage;