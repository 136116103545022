import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SpinnerX from "../../components/Spinner/SpinnerX";


const STaskDetails = () => {
    const { allSTasks } = useSelector((state) => state.stasks);
   const { id } = useParams();
    const [data, setData] = useState(null);
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [checkUserIdInApplicants, setCheckUserIdInApplicants] = useState(false);
       

    useEffect(() => {
        const data = allSTasks && allSTasks.find((i) => i._id === id);
        setData(data);

        const userApplicant = user?._id;

        const isAlreadyApplied =  data.applicants.some((item) => item['applicantId'] === userApplicant );

        if(isAlreadyApplied){
            setCheckUserIdInApplicants(true);
        }

       }, [allSTasks]);
        
        const applicant = {
        applicantName: user?.name,
        applicantEmail: user?.email,
        applicantId: user?._id,
        applicantAvatar: user?.avatar,
        applicantAboutMe: user?.aboutMe,
        applicantLocation: user?.country,
        applicantEducationL: user?.educationLevel,
        applicantLanguage: user?.language,
        staskId:data?._id, 
        }
      
        
      const taskUpdateHandler = async (e) => {
        e.preventDefault();

        const taskStatus = data?.taskStatus;

                await axios
                .put(
                `${server}/stask/update-stask-status/${id}`,
                {
                    taskStatus: taskStatus,
                    applicant: applicant,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Application Successful!");
                  navigate('/s-tasks');
                  window.location.reload();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
          });
      }

      
      
      return (
        <>
        {checkUserIdInApplicants ? 
        <div className="w-full py-5 px-10">
        <h1 className="text-[30px] text-[500]">You've applied for this project already!</h1>
        <p>Please check for other available projects by clicking the button bellow:</p>
        <Link to="/s-tasks" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[15px]">
               View Projects
               </span>
          </div>
        </Link>
        </div>
         : (
          <div className="bg-white">
            {data ? (
                <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
                <div className="w-full py-5">
                  <form onSubmit={taskUpdateHandler}>
                    <div className="block w-full 800px:flex">
                        <div className="w-full 800px:w-[50%] pt-5">
                            <h4 className="pb-3 font-[600] text-[18px]">
                            {data.name}
                            </h4>

                            <h4 className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
                            {data.btdescription}
                            </h4>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Payment:</small> ${data.taskPayment} 
                            <small className="text-[15px]"> /{data.paymentMode}</small>
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className="text-[14px] font-[400]">
                            <small className="text-[15px] text-[blue]">Required Skills:</small> {data.requiredSkills}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Project Duration:</small> {data.taskDuration}
                             <small className="text-[13px]"> {data.durationMode}</small> 
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Location:</small> {data.taskEnvironment}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Opening:</small> {data.targetedCountry}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Applications:</small> {data?.applicants.length}
                            </h5>
                            </div>

                            <br />

                            {user && user?.role === "Worker" && (
                              <div>
                                <div className="800px:w-[50%] text-[16px] text-[blue]">
                                <h5>Worker: {user?.name}</h5>
                                </div> 
                                <br />
                                      
                                <button
                                className={`${styles.button} !h-[42px] text-white !text-[15px]`}
                                type="submit"
                               >
                                Apply
                                </button>
                              </div>
                            )}

                            
                           </div>
                        </div>
                      </form>
                    </div>
                </div>
            ) : <div className="w-full py-5 px-10">
                <h1 className="text-[20px] text-[500] mb-3">Loading Project Details...!</h1>
                <SpinnerX />
                </div>}
            
                </div>
        )}
          </>
       );

        }

export default STaskDetails;


