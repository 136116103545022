import React from "react";
import { AiOutlineFolderAdd, AiOutlineBulb, AiOutlineGift } from "react-icons/ai";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { 
  MdOutlineLocalOffer, 
  MdPlaylistAddCircle, 
  MdPlaylistAddCheckCircle,
  MdPlaylistAddCheck,
  MdPlaylistAdd,
  MdForwardToInbox,
  MdAddHomeWork,
  MdHomeWork,
  MdOutlineVideoCall,
  MdEvent,
} from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { VscNewFile } from "react-icons/vsc";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { ImVideoCamera } from "react-icons/im";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const DashboardSideBar = ({ active }) => {
  const { seller } = useSelector((state) => state.seller);

  return (
    <div className="w-full h-[90vh] bg-white shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link to="/dashboard" className="w-full flex items-center">
          <RxDashboard
            size={20}
            color={`${active === 1 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      {seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link to="/dashboard-orders" className="w-full flex items-center">
          <FiShoppingBag
            size={20}
            color={`${active === 2 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 2 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            All Orders
          </h5>
        </Link>
      </div>
      )}
      

      {seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link to="/dashboard-products" className="w-full flex items-center">
          <FiPackage size={20} color={`${active === 3 ? "blue" : "#555"}`} />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 3 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            All Products
          </h5>
        </Link>
      </div>
      )}
      

      {seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-product"
          className="w-full flex items-center"
        >
          <AiOutlineFolderAdd
            size={20}
            color={`${active === 4 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 4 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Create Product
          </h5>
        </Link>
      </div>
      )}
      

      {seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link to="/dashboard-events" className="w-full flex items-center">
          <MdOutlineLocalOffer
            size={20}
            color={`${active === 5 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 5 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            All Events
          </h5>
        </Link>
      </div>
      )}
      

      {seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link to="/dashboard-create-event" className="w-full flex items-center">
          <VscNewFile
            size={20}
            color={`${active === 6 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 6 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Create Event
          </h5>
        </Link>
      </div>
      )}
      

      <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-withdraw-money"
          className="w-full flex items-center"
        >
          <CiMoneyBill
            size={20}
            color={`${active === 7 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 7 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Withdraw Money
          </h5>
        </Link>
      </div>

      {seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link to="/dashboard-messages" className="w-full flex items-center">
          <MdForwardToInbox
            size={20}
            color={`${active === 8 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 8 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Shop Inbox
          </h5>
        </Link>
      </div>
      )}
      

      {seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link to="/dashboard-coupouns" className="w-full flex items-center">
          <AiOutlineGift
            size={20}
            color={`${active === 9 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 9 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Discount Codes
          </h5>
        </Link>
      </div>
      )}
      

      <div className="w-full flex items-center p-4">
        <Link to="/dashboard-s-tasks" className="w-full flex items-center">
          <MdPlaylistAddCheckCircle
            size={20}
            color={`${active === 10 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 10 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            All Projects
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/dashboard-uns-tasks" className="w-full flex items-center">
          <MdPlaylistAddCheck size={20} color={`${active === 11 ? "blue" : "#555"}`} />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 11 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            My Campaigns
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/dashboard-job" className="w-full flex items-center">
          <MdHomeWork
            size={20}
            color={`${active === 15 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 15 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            All Jobs
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/dashboard-videotasks" className="w-full flex items-center">
          <ImVideoCamera size={20} color={`${active === 17 ? "blue" : "#555"}`} />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 17 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            All Video Tasks
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-s-task"
          className="w-full flex items-center"
        >
          <MdPlaylistAddCircle
            size={20}
            color={`${active === 12 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 12 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Create Project 
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-uns-task"
          className="w-full flex items-center"
        >
          <MdPlaylistAdd
            size={20}
            color={`${active === 13 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 13 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Create Campaign
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-job"
          className="w-full flex items-center"
        >
          <MdAddHomeWork
            size={20}
            color={`${active === 14 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 14 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Post Job 
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-videotask"
          className="w-full flex items-center"
        >
          <MdOutlineVideoCall
            size={20}
            color={`${active === 16 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 16 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Video Task
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-eventAd"
          className="w-full flex items-center"
        >
          <MdEvent
            size={20}
            color={`${active === 18 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 18 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Create EventAd
          </h5>
        </Link>
      </div>


      {seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-extproduct"
          className="w-full flex items-center"
        >
          <AiOutlineBulb
            size={20}
            color={`${active === 19 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 19 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Create ExtProduct
          </h5>
        </Link>
      </div>
      )}

      
      <div className="w-full flex items-center p-4">
        <Link to="/settings" className="w-full flex items-center">
          <CiSettings
            size={20}
            color={`${active === 21 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 21 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Settings
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default DashboardSideBar;
