import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { Country } from "country-state-city";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { freelanceCategoriesData } from "../../../static/data";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../server";
import Spinner from "../../../components/Spinner/Spinner";

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const Hero = () => {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  
  const { user } = useSelector((state) => state.user);
  const [popUp1Open, setPopUp1Open] = useState(false);
  const [specialization, setSpecialization] = useState("");
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  useEffect(() => {
    const userHasUpdated = user?.specialization;
    const cset = user?.country;
  
    // Check if specialization or country is undefined or empty, then show popup
    if (
      !userHasUpdated || userHasUpdated === "" || 
      !cset || cset === ""
    ) {
      setTimeout(() => {
        setPopUp1Open(true);
      }, 6000);
    } else {
      setPopUp1Open(false); // Close the popup if specialization or country is updated
    }
  }, [user]); // Dependency array to trigger when `user` changes



  const specializationHandler = async () => {
    const userId = user?._id;
    setIsLoading(true);
  
    await axios.put(
      `${server}/user/specialization/${userId}`,
      {
        specialization: specialization,
        country: country,
      },
      { withCredentials: true }
    )
    .then((res) => {
      toast.success("Update Successful!");
      setPopUp1Open(false);
      setIsLoading(false);
      window.location.reload(); // Refresh the page if needed
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setPopUp1Open(false);
      setIsLoading(false);
    });
  };

        
  return (
    <>
    {isLoading ? <Spinner /> : (
      <div className="mt-5 pb-8">
      <div
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
        style={{
          backgroundImage:
            "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
        }}
      >
        <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>
          <h1
            className={`text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
          >
            {t('Heading_h')} <br />  <span className="text-[#c89b2a]">{t('Heading_hb')}</span>
          </h1>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          {t('h_p1')}
          </p>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          {t('h_p2')}
          </p>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          {t('h_p3')} 
          </p>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          Have an urgent job for us? hit the 'Urgent Job' button to get it started! 
          </p>
          <Link to="/contact" className="inline-block">
            <div className={`${styles.buttonRed} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('hb_Urgent_Job')}
                 </span>
            </div>
        </Link>
  
          
          <br />
          <br />
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          {t('h_p4')}
          <br />
  
          </p>
  
          {user ? (
            <Link to="/tasks" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('hb_View_Tasks')}
                 </span>
            </div>
        </Link>
          ):(
            <Link to="/sign-up" className="inline-block">
              <div className={`${styles.button} mt-5`}>
                   <span className="text-[#fff] font-[Poppins] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                   {t('hb_Join_Now')}
                   </span>
              </div>
          </Link>
          )}
  
          {popUp1Open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div className={`w-[85%] 800px:w-[50%] bg-[#000] shadow rounded min-h-[40vh] p-3`}>
              <form onSubmit={specializationHandler}>
                      <div>
                      <br />
                      <h4 className="text-[20px] font-[400] text-[#fff]">Welcome {user?.name}!</h4>
                      <h4 className="text-[16px] text-[#fff]">Kindly update your country and area of specialization to enable you make withdrawal and access right tasks.</h4>
                      <br />
  
                      <div>
                      <label
                  htmlFor="specialization"
                  className="block text-sm font-medium text-[#fff]"
                >
                  Country: <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                      <select
                        name=""
                        id=""
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        className="w-[95%] border h-[40px] rounded-[5px]"
                      >
                        <option value="" className="block border pb-2">
                         -
                        </option>
                        {Country &&
                          Country.getAllCountries().map((item) => (
                            <option
                              className="block pb-2"
                              key={item.isoCode}
                              value={item.isoCode}
                            >
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
               </div>
              <br />
  
            <div>
            <label
                  htmlFor="specialization"
                  className="block text-sm font-medium text-[#fff]"
                >
                  Skill Strength: <span className="text-red-500">*</span>
                </label>
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={specialization}
              onChange={(e) => setSpecialization(e.target.value)}
            >
              <option value="Select">-</option>
              {freelanceCategoriesData &&
                freelanceCategoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>
          <br />
  
         
                       <button
                          className={`${styles.button} !bg-[#c89b2a] !w-[120px] !text-[14px] text-white`}
                          type="submit"
                        >
                          Update
                        </button>
                      </div>
                      <button
                          className={`${styles.button} !bg-[#c89b2a] !w-[120px] !text-[14px] text-white mt-5`}
                          onClick={() => setPopUp1Open(false)}
                         >
                         Cancle
                        </button>
                      </form>
            </div>
            
          </div>
        )}
             
        </div>
       </div>
       </div>
    )}
    </>
  );
};

export default Hero;

/*
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { freelanceCategoriesData } from "../../../static/data";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../server";
import { RxCross1 } from "react-icons/rx";
import { format } from "date-fns";  // Use date-fns for date formatting and comparison

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const Hero = () => {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  
  const { user } = useSelector((state) => state.user);
  const [popUp1Open, setPopUp1Open] = useState(false);
  const [popUp2Open, setPopUp2Open] = useState(false);
  const [specialization, setSpecialization] = useState("");
  const [mycontrolData, setMycontrolData] = useState([]);

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  useEffect(() => {
    const userHasUpdated = user?.specialization;

    if(user && userHasUpdated === undefined || userHasUpdated === ""){
      setTimeout(() => {
        setPopUp1Open(true);
      }, 6000)
    }
  }, []);



  useEffect(() => {
    axios
      .get(`${server}/mycontrol/get-all-mycontrols`, {
        withCredentials: true,
      })
      .then((res) => {
        setMycontrolData(res.data.mycontrols);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);


  useEffect(() => {
    // Get the current date in 'yyyy-MM-dd' format
    const currentDate = format(new Date(), 'yyyy-MM-dd');

    // Check if taskCompletionDate exists for the user
    const taskCompletionDate = user && user.hasSubscribedDate;  // Assuming user has `hasSubscribedDate` field
    
    // If no taskCompletionDate (new user or undefined), treat as a new day
    if (!taskCompletionDate || user && user.hasSubscribed === "no" && user.specialization !== undefined || user.specialization !== "") {
      console.log("No task completion date found, assuming new user.");
      setPopUp2Open(true);  // Open the popup for new user
      return;  // Exit early as no need to compare dates
    }

    // If taskCompletionDate exists, format it to 'yyyy-MM-dd'
    const storedTaskDate = format(new Date(taskCompletionDate), 'yyyy-MM-dd');

    console.log("Task completion date: " + storedTaskDate); // Debugging: checking the comparison
    console.log("Current date: " + currentDate); // Debugging: checking the comparison

    // If taskCompletionDate is not the current date, it's a new day or task hasn't been completed today
    if (user && user.hasSubscribed === "yes" && storedTaskDate !== currentDate && user.specialization !== undefined || user.specialization !== "") {
      setTimeout(() => {
        setPopUp2Open(true);  // Show the popup if it's a new day
      }, 3000);
    }
  }, [user]);  // Make sure the effect runs when the `user` changes


  const controlPin2 = "114455chris2";

  const mcDataToUse2 = mycontrolData && mycontrolData.find((itii) => itii.controlPin === controlPin2);
  console.log(mcDataToUse2);

  const specializationHandler = async () => {

    const userId = user?._id;

    await axios.put(
                `${server}/user/specialization/${userId}`,
                {
                  specialization: specialization,
                    },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Update Successful!");
                  setPopUp1Open(false);
                  window.location.reload();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
                setPopUp1Open(false);
              });
        }


        const subscribeHandler = async () => {

          const userId = user?._id;
      
          await axios.put(
                      `${server}/user/subscribetotel/${userId}`,
                      {
                        hasSubscribed: "yes",
                          },
                      { withCredentials: true }
                    )
                    .then((res) => {
                        setPopUp2Open(false);
                        window.location.reload();
                        })
                     .catch((error) => {
                      toast.error(error.response.data.message);
                      setPopUp2Open(false);
                    });
              }

  return (
    <div className="mt-5 pb-8">
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>
        <h1
          className={`text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
          {t('Heading_h')} <br />  <span className="text-[#c89b2a]">{t('Heading_hb')}</span>
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        {t('h_p1')}
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        {t('h_p2')}
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        {t('h_p3')} 
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Have an urgent job for us? hit the 'Urgent Job' button to get it started! 
        </p>
        <Link to="/contact" className="inline-block">
          <div className={`${styles.buttonRed} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               {t('hb_Urgent_Job')}
               </span>
          </div>
      </Link>

        
        <br />
        <br />
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        {t('h_p4')}
        <br />

        </p>

        {user ? (
          <Link to="/tasks" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               {t('hb_View_Tasks')}
               </span>
          </div>
      </Link>
        ):(
          <Link to="/sign-up" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('hb_Join_Now')}
                 </span>
            </div>
        </Link>
        )}

        {popUp1Open && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          <div className={`w-[85%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}>
            <form onSubmit={specializationHandler}>
                    <div>
                    <br />
                    <h4 className="text-[25px] font-[400]">Welcome {user?.name}!</h4>
                    <h4 className="text-[16px]">Kindly update your area of specialization.</h4>
                    <br />

          <div>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={specialization}
            onChange={(e) => setSpecialization(e.target.value)}
          >
            <option value="Select">Select</option>
            {freelanceCategoriesData &&
              freelanceCategoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>
        <br />
       
                     <button
                        className={`${styles.button} !h-[42px] text-white`}
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                    <button
                        className={`${styles.button} !h-[42px] text-white mt-5`}
                        onClick={() => setPopUp1Open(false)}
                       >
                       Cancle
                      </button>
                    </form>
          </div>
          
        </div>
      )}


        {popUp2Open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div className={`w-[85%] 800px:w-[50%] min-h-[50vh] p-3`}>
              <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-[#fff] py-4 px-4 shadow-lg sm:rounded-lg sm:px-10">

              <div className="space-y-6 items-center text-center justify-center">
            <div className="flex justify-between px-4">
              <p className="text-[18px] font-[500] mr-6">🚀Announcement!!!</p>
              <RxCross1 size={20} className="cursor-pointer" onClick={() => setPopUp2Open(false)} />
            </div>
            <div>
              <p className="text-[#000] whitespace-pre-line">{mcDataToUse2?.adsDetails === undefined ? "Click the 'Join Now' button bellow to join our official Telegram channel and enjoy amazing gifts and bonuses." : mcDataToUse2.adsDetails}</p>
            </div>

            <div>
              <Link to={mcDataToUse2?.adsLink === undefined ? "https://t.me/+fShja-eUKkA2YzFk" : mcDataToUse2?.adsLink} target="_blank">
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000]"
                onClick={subscribeHandler}
              >
               {mcDataToUse2?.buttonText === undefined ? "Join Now" : mcDataToUse2?.buttonText}
              </button>
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
)}
        
      </div>
     </div>
     </div>
  );
};

export default Hero;

*/