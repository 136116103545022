import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const extproductReducer = createReducer(initialState, {
  extproductCreateRequest: (state) => {
    state.isLoading = true;
  },
  extproductCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.extproduct = action.payload;
    state.success = true;
  },
  extproductCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // get all extproducts of shop
  getAllExtProductsShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllExtProductsShopSuccess: (state, action) => {
    state.isLoading = false;
    state.extproducts = action.payload;
  },
  getAllExtProductsShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete extproduct of a shop
  deleteExtProductRequest: (state) => {
    state.isLoading = true;
  },
  deleteExtProductSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteExtProductFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all extproducts
  getAllExtProductsRequest: (state) => {
    state.isLoading = true;
  },
  getAllExtProductsSuccess: (state, action) => {
    state.isLoading = false;
    state.allExtProducts = action.payload;
  },
  getAllExtProductsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  
  clearErrors: (state) => {
    state.error = null;
  },
});
