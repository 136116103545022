import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { server } from "../../server"; // Assuming your server URL is defined here

const LinkedInSignUp = () => {
  const navigate = useNavigate();

  // This function will be called once the LinkedIn SDK has loaded
  window.onLinkedInLoad = () => {
    window.IN.init();
  };

  // Handle LinkedIn login using the SDK
  const handleLinkedInLogin = () => {
    const scope = 'r_liteprofile r_emailaddress';  // Define the scope

    // Request user authorization with the scope
    window.IN.User.authorize(() => {
      // After authorization, retrieve the user's LinkedIn data
      window.IN.API.Raw('/people/~:(id,email-address,first-name,last-name)').result((response) => {
        const { id, emailAddress, firstName, lastName } = response;

        // Send the LinkedIn data to your backend
        axios
          .post(`${server}/user/linkedin`, { linkedinId: id, email: emailAddress, name: `${firstName} ${lastName}` })
          .then((res) => {
            localStorage.setItem('user_Token', res.data.token);  // Store JWT token in localStorage
            toast.success('LinkedIn Login Successful');
            navigate('/');  // Redirect to home or dashboard
            window.location.reload(true);  // Refresh the page to update user state
          })
          .catch((err) => {
            toast.error('LinkedIn login failed');
          });
      });
    }, scope);  // Pass the scope to request LinkedIn permissions
  };

  return (
    <div className='mt-4 font-[600]'>
      <button
        className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#0077B5] hover:bg-[#000]" 
        onClick={handleLinkedInLogin}>
        <AiOutlineLinkedin size={25} className="mr-3" /> Signin/up with LinkedIn
      </button>
    </div>
  );
};

export default LinkedInSignUp;