import React, { useState } from "react";
import {
  AiOutlineEye,
  AiOutlineShoppingCart  
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import ExtProductDetailsCard from "../ExtProductDetailsCard/ExtProductDetailsCard";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

const ExtProductCard = ({ data }) => {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

 const [open, setOpen] = useState(false);
  

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])
  

  
  
  return (
    <>
      <div className="w-full h-[410px] bg-[#F6F6F5] rounded-lg shadow-sm p-3 relative cursor-pointer">
       <Link to={`/extproduct/${data._id}`}>
          <img
            src={`${data.images && data.images[0]?.url}`}
            alt=""
            className="w-full h-[170px] object-contain px-8 py-4"
          />
        </Link>
        <Link to={`/shop/preview/${data?.shop._id}`}>
          <h5 className={`${styles.shop_name}`}>{data.shop.name}</h5>
        </Link>
        <Link to={`/extproduct/${data._id}`}>
          <h4 className="pb-3 font-[500] text-[#000]">
            {data.name.length > 80 ? data.name.slice(0, 120) + "..." : data.name}
          </h4>

          <div className="py-2 flex items-center justify-between">
            <div className="flex">
              <h5 className={`${styles.productDiscountPrice}`}>
              ${data.originalPrice === 0
                  ? data.originalPrice
                  : data.discountPrice}
                
              </h5>
              <h4 className={`${styles.price}`}>
                ${data.originalPrice ? data.originalPrice : null}
              </h4>
            </div>
           </div>

        </Link>
        <Link to={data.checkoutURL ? data.checkoutURL : "#"} target="_blank">
                <div
                  className={`${styles.button} !w-[100px] !bg-[teal] mt-4 rounded-[4px] h-11`}
                >
                  <span className="text-[#fff] !text-[12px] flex items-center">
                    Buy/Info <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </div>
                </Link>

       {/* side options */}
        <div>
          
          <AiOutlineEye
            size={22}
            className="cursor-pointer absolute right-2 top-14"
            onClick={() => setOpen(!open)}
            color="#333"
            title="Quick view"
          />
          {open ? <ExtProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div>
      </div>
    </>
  );
};

export default ExtProductCard;


