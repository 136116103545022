import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { useSelector } from "react-redux";


const PackageAccess = () => {
    const { id } = useParams();
    const [dataToUse, setDataToUse] = useState(null);
    const { user } = useSelector((state) => state.user);
    
    
    useEffect(() => {
       axios
          .get(`${server}/talent/get-all-talents`, {
            withCredentials: true,
          })
          .then((res) => {
            setDataToUse(res.data.talents);
        })
          .catch((error) => {
            console.log(error.response.data.message);
           });
      }, []);

                

        const data = dataToUse && dataToUse.find((i) => i._id === id);

        const userDoingTask = user?._id;
        const userDoingTask2 = user?._id;

        const isSubscribed =  data && data.subscribers.some((item) => item['subscriberId'] === userDoingTask );

        const isPurchased =  data && data.fullPackPurchasers.some((itemm) => itemm['fullPackPurchaserId'] === userDoingTask2 );

        const Access1 = data?.access1URL;
        const Access2 = data?.access2URL;
        const Access3 = data?.access3URL;
        const Access4 = data?.access4URL;
        const Access5 = data?.access5URL;

        const FAccess1 = data?.fullPA1URL;
        const FAccess2 = data?.fullPA2URL;

      return (
        <>
          <div className="bg-white">
            {data ? (
                <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
                <div className="w-full py-5">
                  <form>
                    <div className="block w-full 800px:flex">
                        <div className="w-full 800px:w-[50%] pt-5">
                            <h4 className="pb-3 font-[600] text-[18px]">
                            {data.name}
                            </h4>

                            <h4 className="pt-5 text-[16px] text-[#000000ba] leading whitespace-pre-line" dangerouslySetInnerHTML={{ __html: data?.description }}>
                            </h4>

                            <div className="py-2 flex items-center justify-between">
                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice}`}>
                            <small className="font-[400] text-[15px] text-[green]">Subscription:</small> ${data.subscriptionAmount} Monthly
                            </h5>
              
                            </div>
                            <span className="font-[400] text-[15px] text-[green]">
                            Charge: from ${data.charge}
                            </span>
                            </div>
                            <br />

                            {isSubscribed && (
                                <>
                                <h5>Congratulations on subscribing to this package. Kindly Access files via the button(s) bellow:</h5>

                            {Access1 !== "" ? (
                              <Link to={data?.access1URL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[15px]">
                              Access 1
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {Access2 !== "" ? (
                              <Link to={data?.access2URL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[15px]">
                              Access 2
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {Access3 !== "" ? (
                              <Link to={data?.access3URL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[15px]">
                              Access 3
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {Access4 !== "" ? (
                              <Link to={data?.access4URL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[15px]">
                              Access 4
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {Access5 !== "" ? (
                              <Link to={data?.access5URL} target="_blank" className="inline-block">
                              <div className={`${styles.button} mt-5 mr-2`}>
                              <span className="text-[#fff] font-[Poppins] text-[15px]">
                              Access 5
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                                </>
                            )}

                            <br />
                            <br />

                          {isPurchased && (
                              <>
                              <h5>Congratulations on purchasing this package. Kindly Access files via the button(s) bellow:</h5>

                          {FAccess1 !== "" ? (
                          <Link to={data?.fullPA1URL === null ? "#" : data?.fullPA1URL} target="_blank" className="inline-block">
                          <div className={`${styles.button} mt-5 mr-2`}>
                          <span className="text-[#fff] font-[Poppins] text-[15px]">
                          Pack Access 1
                          </span>
                        </div>
                        <br />
                        </Link>
                      ) : null}

                      {FAccess2 !== "" ? (
                      <Link to={data?.fullPA2URL === null ? "#" : data?.fullPA2URL} target="_blank" className="inline-block">
                      <div className={`${styles.button} mt-5 mr-2`}>
                      <span className="text-[#fff] font-[Poppins] text-[15px]">
                      Pack Access 2
                      </span>
                      </div>
                      <br />
                      </Link>
                      ) : null}
                              </>
                            )}
                           
                            
                           </div>
                        </div>
                      </form>
                    </div>
                </div>
            ) : <div className="w-full py-5 px-10">
                <h1 className="text-[30px] text-[500]">Loading Gig Details...!</h1>
                <p>It may take few seconds</p>
                </div>}
                </div>
      </>
        );

        }

export default PackageAccess;
