import React, { useEffect, useState } from "react";
import {
  AiOutlineMessage,
  AiOutlineVideoCamera,
  AiOutlineShoppingCart
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import styles from "../../styles/styles";

import YoutubeEmbed from "../../components/YoutubeEmbed";
import "../../components/YoutubeEmbed.css";

const ExtProductDetails = ({ data }) => {
  const [select, setSelect] = useState(0);
  //const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
 
 
  const YoutubeVideoId = data?.videoSupportId;
  
  
  return (
    <>
    <div className="bg-white">
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">

	        <div className="block w-full 800px:flex">
              <div className="w-full 800px:w-[50%]">
                <img
                  src={`${data && data.images[select]?.url}`}
                  alt=""
                  className="w-[80%]"
                />
                <div className="w-full flex">
                  {data &&
                    data.images.map((i, index) => (
                      <div
                        className={`${
                          select === 0 ? "border" : "null"
                        } cursor-pointer`}
                      >
                        <img
                          src={`${i?.url}`}
                          alt=""
                          className="h-[200px] overflow-hidden mr-3 mt-3"
                          onClick={() => setSelect(index)}
                        />
                      </div>
                    ))}
                  <div
                    className={`${
                      select === 1 ? "border" : "null"
                    } cursor-pointer`}
                  ></div>
                </div>
              </div>
              <div className="w-full 800px:w-[50%] pt-5">
                <h1 className={`${styles.productTitle}`}>{data.name}</h1><br />

                {YoutubeVideoId !== "" ? (
                  <div className="w-full">
                  <YoutubeEmbed embedId={data?.videoSupportId} />
                  </div>
                ) : null}
                <br />

                {data?.extvideoSupportURL !== "" ? (
                    <Link to={data?.extvideoSupportURL} target="_blank" className="800px:ml-3">
                    <div
                      className={`${styles.button} bg-[#6443d1] mt-4 !rounded !h-11`}
                    >
                      <span className="text-white flex items-center !text-[14px]">
                        Watch Video <AiOutlineVideoCamera className="ml-1" />
                      </span>
                    </div>
                  </Link>
                ) : null}

                <p className="py-2 text-[18px] leading-2 whitespace-pre-line">
                {data.description.length > 150 ? data.description.slice(0, 150) + "...see full product details bellow:" : data.description}
                </p>
                <div className="flex pt-3">
                  <h4 className={`${styles.productDiscountPrice}`}>
                    {data.discountPrice}$
                  </h4>
                  <h3 className={`${styles.price}`}>
                    {data.originalPrice ? data.originalPrice + "$" : null}
                  </h3>
                </div>

                <Link to={data.checkoutURL ? data.checkoutURL : "#"} target="_blank">
                <div
                  className={`${styles.button} !bg-[teal] mt-4 rounded-[4px] h-11`}
                >
                  <span className="text-[#fff] flex items-center">
                    Buy/Info <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </div>
                </Link>

                <div className="block 800px:flex items-center pt-8">
                  <Link to={`/shop/preview/${data?.shop._id}`}>
                    <img
                      src={`${data?.shop?.avatar?.url}`}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full mr-2"
                    />
                  </Link>
                  <div className="pr-8">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3 className={`${styles.shop_name} pb-1 pt-1`}>
                        {data.shop.name}
                      </h3>
                    </Link>
                    </div>
                  <Link to="https://wa.me/447418375846" target="_blank" className="800px:ml-3">
                  <div
                    className={`${styles.button} bg-[#6443d1] mt-4 !rounded !h-11`}
                  >
                    <span className="text-white flex items-center !text-[14px]">
                      WhatsApp <AiOutlineMessage className="ml-1" />
                    </span>
                  </div>
                </Link>
                </div>
              </div>
            </div>
          </div> <br />

                    
          <ExtProductDetailsInfo
            data={data}
            products={products}
            />
          <br />
          <br />
        </div>
      ) : null}
    </div>
    </>
    
  );
};

const ExtProductDetailsInfo = ({
  data,
  products,
  }) => {
  const [active, setActive] = useState(1);

  return (
    <div className="bg-[#f5f6fb] px-3 800px:px-10 py-2 rounded">
      <div className="w-full flex justify-between border-b pt-10 pb-2">
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(1)}
          >
            Product Details
          </h5>
          {active === 1 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
        
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(2)}
          >
            Seller Information
          </h5>
          {active === 2 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
      </div>

      {active === 1 ? (
        <>
          <p className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
            {data.description}
          </p>
        </>
      ) : null}
      
      {active === 2 && (
        <div className="w-full block 800px:flex p-5">
          <div className="w-full 800px:w-[50%]">
            <Link to={`/shop/preview/${data.shop._id}`}>
              <div className="flex items-center">
                <img
                  src={`${data?.shop?.avatar?.url}`}
                  className="w-[50px] h-[50px] rounded-full"
                  alt=""
                />
                <div className="pl-3">
                  <h3 className={`${styles.shop_name}`}>{data.shop.name}</h3>
                 </div>
              </div>
            </Link>
            <p className="pt-2">{data.shop.description}</p>
          </div>
          <div className="w-full 800px:w-[50%] mt-5 800px:mt-0 800px:flex flex-col items-end">
            <div className="text-left">
              <h5 className="font-[600]">
                Joined on:{" "}
                <span className="font-[500]">
                  {data.shop?.createdAt?.slice(0, 10)}
                </span>
              </h5>
              <h5 className="font-[600] pt-3">
                Total Products:{" "}
                <span className="font-[500]">
                  {products && products.length}
                </span>
              </h5>
              <Link to={`/shop/preview/${data?.shop._id}`}>
                <div
                  className={`${styles.button} !rounded-[4px] !h-[39.5px] mt-3`}
                >
                  <h4 className="text-white">Visit Shop</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtProductDetails;
