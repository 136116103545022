import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "../styles/styles";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Spinner from "../components/Spinner/Spinner";
import { Link } from "react-router-dom";

const UserTransferMoney = () => {
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [withdrawAmount, setWithdrawAmount] = useState(1);
  const [receiver, setReceiver] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const bonusBalance = user?.bonusBalance.toFixed(4); 
  const email = user?.email;
  
  const error = () => {
    toast.error("You don't have enough tokens to transfer!");
  };

  const amount = Number(withdrawAmount);
  const transferUserId = user?._id;
      
  const bonusWithdrawHandler = async (e) => {
    e.preventDefault();

   if (withdrawAmount > bonusBalance){
      return toast.error("Insuficient Tokens!");
    } else {
      setIsLoading(true);
      await axios
        .post(
          `${server}/transfer/create-transfer-request-user`,
          { amount, email, receiver, transferUserId, password },
          { withCredentials: true }
        )
        .then((res) => {
          setIsLoading(false);
          toast.success("Transfer successful!");
          window.location.reload();
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("Wrong password!");
        });
    }
  };

  
  return (
    <div className="w-full h-[90vh] p-8">
      {isLoading ? <Spinner /> 
      : (
        <>
        <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
        <div className="800px:w-[30%] 800px:h-[300px] bg-[#F6F6F5] w-[90%] h-[70%] rounded flex items-center justify-center flex-col">
        <h5 className="text-[18px] text-[blue]">
          Token Balance: 
        </h5>
        <h2 className="text-[25px] font-[600] pb-1">
        {bonusBalance}TST
        </h2>
        <div
          className={`${styles.button} text-white !h-[42px] !rounded`}
          onClick={() => (bonusBalance < 10 ? error() : setOpen(true))}
        >
          Transfer
        </div>
        <Link to="/profile">
        <h5 className="text-[18px] text-[blue] pt-1">
         Cancel 
        </h5>
        </Link>
        </div>
        </div>
      {open && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          
          <div
            className={`w-[90%] 800px:w-[30%] bg-white shadow rounded min-h-[40vh] p-3`}
          >
                   
                
                   <form onSubmit={bonusWithdrawHandler}>
                    <div>
                    <br />
                    <h5 className="text-[23px] text-[600]">Tokens:</h5>
                    <h4 className="text-[35px] font-[600]">{bonusBalance}TST</h4>
                    <br />
                    
                    <div className="flex w-[full] items-center">
                    <label className="text-[16px] text-[600]">Tokens: </label>
                      <input
                        type="number"
                        placeholder="Amount..."
                        required
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                        className="w-[full] border 800px:mr-3 p-1 rounded bg-[#F6F6F5]"
                      />
                      </div>
                      <br />

                      <div className="flex w-[full] items-center">
                    <label className="text-[16px] text-[600]">Sender's Email: </label>
                      <input
                        type="email"
                        value={email}
                        className="w-[full] border 800px:mr-3 p-1 rounded bg-[#F6F6F5]"
                      />
                      </div>
                      <br />

                      <div className="flex w-[full]">
                    <label className="text-[16px] text-[600]">Receiver's Email:</label>
                      <input
                        type="email"
                        placeholder="Receiver's Email"
                        required
                        value={receiver}
                        onChange={(e) => setReceiver(e.target.value)}
                        className="w-[full] border 800px:mr-3 p-1 rounded bg-[#F6F6F5]"
                      />
                      </div>
                      <br />

                      <div>
                    <label className="block text-sm font-medium text-gray-700">Password:</label>
                    <div className="mt-1 relative">
                      <input
                        type={visible ? "text" : "password"}
                        placeholder="enter password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-[#F6F6F5]"
                      />
                      {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
                </div>
                </div>
                <br />

                      <button
                        className={`${styles.button} !h-[42px] text-white`}
                        type="submit"
                      >
                        Transfer
                      </button>
                    </div>
                    <Link to="/profile">
                      <button
                        className={`${styles.button} !h-[42px] text-white mt-5`}
                       >
                        Cancel
                      </button>
                      </Link>
                  </form>
          </div>
          
        </div>
      )}
        </>
      )}
      
    </div>
  );
};

export default UserTransferMoney;
