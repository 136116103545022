import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from "../../server";
import {
    AiOutlineFacebook
} from "react-icons/ai";

const FacebookSignUp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize Facebook SDK only after the script is loaded
    const initializeFacebookSDK = () => {
      if (window.FB) {
        window.FB.init({
          appId: '1005733654909508',
          cookie: true,
          xfbml: true,
          version: 'v12.0', // specify the correct version
        });
      } else {
        console.error("Facebook SDK not loaded");
      }
    };

    // Check if the script is already loaded
    if (window.FB) {
      initializeFacebookSDK();
    } else {
      // Add event listener to run when SDK script has loaded
      window.fbAsyncInit = initializeFacebookSDK;
    }
  }, []);

  const handleFacebookLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const { accessToken } = response.authResponse;

          console.log("Backend response data:", accessToken);  // Log the full response data to see the structure

          // Send the access token to your backend for verification
          axios
            .post(`${server}/user/facebook-login`, { accessToken })
            .then((res) => {
              localStorage.setItem('user_Token', res.data.token);
              toast.success('Facebook Login Successful');
              navigate('/');
              window.location.reload(true);
            })
            .catch((err) => {
              toast.error('Facebook login failed');
            });
        } else {
          toast.error('Facebook login failed');
        }
      },
      { scope: 'public_profile,email' } // request additional permissions
    );
  };

  return (
    <div className='mt-4 font-[600]'>
      <button
        className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#385996] hover:bg-[#000]" 
        onClick={handleFacebookLogin}>
        <AiOutlineFacebook size={25} className="mr-3" /> Signin/up with Facebook
      </button>
    </div>
  );
};

export default FacebookSignUp;