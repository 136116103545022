import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  LoginPage,
  LoginWithPinPage,
  SignupPage,
  SignupRefPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  TasksPage,
  STasksPage2,
  BestSellingPage,
  EventsPage,
  FAQPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ProductDetailsPage,
  ExtProductDetailsPage,
  TaskDetailsPage,
  STaskDetailsPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  OrderDetailsPage,
  TrackOrderPage,
  UserInbox,
  ContactPage,
  GraphicsPage,
  BrandingPage,
  SMEPage,
  WnMAppDevPage,
  OPSPage,
  ShippingPage,
  CareerPage,
  CVPage,
  PrivacyPolicyPage,
  DataDeletionPage,
  PaystackPaymentPage,
  UserWithDrawMoneyPage,
  TaskTnCPage,
  ProductTestingPage,
  UserTransferMoneyPage,
  CompressorPage,
  UserWCommPage,
  CVendorsPage,
  TFoundationPage,
  ProductAffiliateDetailsPage,
  ViewApplicantsPage,
  AssignWorkerPage,
  UserExchangePage,
  UserInvestPage,
  RCHomePage,
  ArticleDetails,
  CreateArticlePage,
  ProjectWorkPage,
  ViewJobSamplesPage,
  DJobPage,
  DJobDetailsPage,
  SendSamplesPage,
  CVendorApplicationPage,
  TaxInfoPage,
  RWHomePage,
  VideoTasksPage,
  VideoTaskDetailsPage,
  UserInvestDetailPage,
  AcademyPage,
  TestsPage,
  AudioTranscriptionTestPage,
  CoursePage,
  CreateTalentPage,
  TalentPage,
  TalentDetailPage,
  PackageAccessPage,
  EventAdPage,
  UserGigsPage,
  TranscriptionPage,
  IVInvitesPage,
  GraphicTestPage,
 } from "./routes/Routes.js";
import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopCreateExtProduct,
  ShopCreateTask,
  ShopCreateSTask,
  ShopAllProducts,
  ShopAllTasks,
  ShopCreateEvents,
  ShopAllEvents,
  ShopAllCoupouns,
  ShopPreviewPage,
  ShopAllOrders,
  ShopAllSTasks,
  ShopSettingsPage,
  ShopWithDrawMoneyPage,
  ShopInboxPage,
  ShopCreateJob,
  ShopAllDJobs,
  ShopDepositMoneyPage,
  ShopCreateVideoTask,
  ShopAllVideoTasks,
  ShopCreateEventsAd,
} from "./routes/ShopRoutes";
import {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEvents,
  AdminDashboardWithdraw,
  AdminDashboardWithdrawUser,
  AdminDashboardTasks,
  AdminDashboardTransfer,
  AdminDashboardNewCode,
  AdminDashboardUsedCode,
  AdminActionPage,
} from "./routes/AdminRoutes";
import {
  CodeVendorDashboardPage,
  CodeVendorDashboardNewCodes,
  CodeVendorDashboardUsedCodes,
} from "./routes/CodeVendorRoutes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import { loadUser } from "./redux/actions/user";
import { loadSeller } from "./redux/actions/sellers";

import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
import ProtectedCodeVendorRoute from "./routes/ProtectedCodeVendorRoutes.js";
import { ShopHomePage } from "./ShopRoutes.js";
import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import { getAllProducts } from "./redux/actions/product";
import { getAllExtProducts } from "./redux/actions/extproduct";
import { getAllTasks } from "./redux/actions/task";
import { getAllSTasks } from "./redux/actions/stask";
import { getAllEvents } from "./redux/actions/event";

//import axios from "axios";
//import { server } from "./server";



const App = () => {
  
    
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
    Store.dispatch(getAllExtProducts());
    Store.dispatch(getAllTasks());
    Store.dispatch(getAllSTasks());
    Store.dispatch(getAllEvents());
    
  }, []);

  return (
    <BrowserRouter>
      <Routes>
            <Route
              path="/payment"
              element={
                <ProtectedRoute>
                  <PaymentPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/birthday/erhuvwuokokoro" element={<IVInvitesPage />} />
        
        <Route path="/login" element={<LoginPage />} />

        <Route path="/login-with-pin" element={<LoginWithPinPage />} />
        
        <Route path="/sign-up" element={<SignupPage />} />

        <Route path="/sign-up/ref/:username" element={<SignupRefPage />} />
        
        <Route
          path="/user/activation/:activation_token"
          element={<ActivationPage />}
        />
        <Route
          path="/seller/activation/:activation_token"
          element={<SellerActivationPage />}
        />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/transcription" element={<TranscriptionPage />} />
        <Route path="/tasks" element={<TasksPage />} />
        <Route path="/videotasks" element={<VideoTasksPage />} />
        <Route path="/s-tasks" element={<STasksPage2 />} />
        <Route path="/jobs" element={<DJobPage />} />
        <Route path="/academy" element={<AcademyPage />} />
        <Route path="/academy/courses" element={<CoursePage />} />
        <Route path="/ads/events" element={<EventAdPage />} />

        <Route path="/user-gigs/:id" element={<UserGigsPage />} />
               
        <Route path="/product/:id" element={<ProductDetailsPage />} />
        <Route path="/extproduct/:id" element={<ExtProductDetailsPage />} />
        <Route path="/product/:id/affiliate/:username" element={<ProductAffiliateDetailsPage />} />
        <Route path="/task/:id" element={<TaskDetailsPage />} />
        <Route path="/talent/:id" element={<TalentDetailPage />} />
        <Route path="/videotask/:id" element={<VideoTaskDetailsPage />} />
        <Route path="/djob/:id" element={<DJobDetailsPage />} />
        <Route path="/s-task/:id" element={<STaskDetailsPage />} />
        <Route path="/s-task/view-applicants/:id" element={<ViewApplicantsPage />} />
        <Route path="/djob/view-samples/:id" element={<ViewJobSamplesPage />} />
        <Route path="/djob/send-samples-worker/:id" element={<SendSamplesPage />} />
        <Route path="/assign-worker/:workaId/to-project/:id" element={<AssignWorkerPage />} />
        <Route path="/project-access/projectId/:id" element={<ProjectWorkPage />} />
        
        <Route path="/best-selling" element={<BestSellingPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/rewards-earning-structure" element={<RWHomePage />} />
        <Route path="/resource-center" element={<RCHomePage />} />
        <Route path="/resource-center/article/:id" element={<ArticleDetails />} />
        <Route path="/article/postnew" element={<CreateArticlePage />} />

        <Route path="/graphics" element={<GraphicsPage />} />
        <Route path="/branding" element={<BrandingPage />} />
        <Route path="/product-testing" element={<ProductTestingPage />} />
        <Route path="/social-media-marketing" element={<SMEPage />} />
        <Route path="/web-and-mobile-app-dev" element={<WnMAppDevPage />} />
        <Route path="/othercountries" element={<OPSPage />} />

        <Route path="/terms-of-service" element={<ShippingPage />} />
        <Route path="/image-compressor" element={<CompressorPage />} />
        <Route path="/code-vendors" element={<CVendorsPage />} />
        <Route path="/cvendors-application" element={<CVendorApplicationPage />} />
        <Route path="/career" element={<CareerPage />} />
        <Route path="/cv" element={<CVPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/data-deletion" element={<DataDeletionPage />} />
        <Route path="/task-submission-terms-and-condition" element={<TaskTnCPage />} />
        <Route path="/paystack" element={<PaystackPaymentPage />} />
        <Route path="/totlesoft-foundation" element={<TFoundationPage />} />
        <Route path="/tax-info" element={<TaxInfoPage />} />
        <Route path="/talents/gigs" element={<TalentPage />} />
                
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <CheckoutPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/create-gig"
          element={
            <ProtectedRoute>
              <CreateTalentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard-withdraw-money-user"
          element={
            <ProtectedRoute>
              <UserWithDrawMoneyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard-transfer-money-user"
          element={
            <ProtectedRoute>
              <UserTransferMoneyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard-convert-point-user"
          element={
            <ProtectedRoute>
              <UserWCommPage />
            </ProtectedRoute>
          }
        />
        
        <Route
          path="/dashboard-exchange-bonus-user"
          element={
            <ProtectedRoute>
              <UserExchangePage />
            </ProtectedRoute>
          }
        />
        
        <Route
          path="/dashboard-invest"
          element={
            <ProtectedRoute>
              <UserInvestPage />
            </ProtectedRoute>
          }
        />
        <Route path="/order/success" element={<OrderSuccessPage />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inbox"
          element={
            <ProtectedRoute>
              <UserInbox />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tests"
          element={
            <ProtectedRoute>
              <TestsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/order/:id"
          element={
            <ProtectedRoute>
              <OrderDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/package-access/:userId/:id"
          element={
            <ProtectedRoute>
              <PackageAccessPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/i/details/:id"
          element={
            <ProtectedRoute>
              <UserInvestDetailPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/track/order/:id"
          element={
            <ProtectedRoute>
              <TrackOrderPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tests/audio-transcription-tests"
          element={
            <ProtectedRoute>
              <AudioTranscriptionTestPage />GraphicTestPage
            </ProtectedRoute>
          }
        />

        <Route
          path="/tests/graphic-design-tests"
          element={
            <ProtectedRoute>
              <GraphicTestPage />
            </ProtectedRoute>
          }
        />
        {/* miner Routes */}

        

        
        {/* shop Routes */}
        <Route path="/shop/preview/:id" element={<ShopPreviewPage />} />
        <Route path="/shop-create" element={<ShopCreatePage />} />
        <Route path="/shop-login" element={<ShopLoginPage />} />
        <Route
          path="/shop/:id"
          element={
            <SellerProtectedRoute>
              <ShopHomePage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <SellerProtectedRoute>
              <ShopSettingsPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-product"
          element={
            <SellerProtectedRoute>
              <ShopCreateProduct />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-extproduct"
          element={
            <SellerProtectedRoute>
              <ShopCreateExtProduct />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-uns-task"
          element={
            <SellerProtectedRoute>
              <ShopCreateTask />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-videotask"
          element={
            <SellerProtectedRoute>
              <ShopCreateVideoTask />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-s-task"
          element={
            <SellerProtectedRoute>
              <ShopCreateSTask /> 
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-job"
          element={
            <SellerProtectedRoute>
              <ShopCreateJob /> 
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-orders"
          element={
            <SellerProtectedRoute>
              <ShopAllOrders />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-s-tasks"
          element={
            <SellerProtectedRoute>
              <ShopAllSTasks />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/dashboard-products"
          element={
            <SellerProtectedRoute>
              <ShopAllProducts />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-uns-tasks"
          element={
            <SellerProtectedRoute>
              <ShopAllTasks /> 
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-videotasks"
          element={
            <SellerProtectedRoute>
              <ShopAllVideoTasks /> 
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-job"
          element={
            <SellerProtectedRoute>
              <ShopAllDJobs /> 
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-event"
          element={
            <SellerProtectedRoute>
              <ShopCreateEvents />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-eventAd"
          element={
            <SellerProtectedRoute>
              <ShopCreateEventsAd />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-events"
          element={
            <SellerProtectedRoute>
              <ShopAllEvents />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-coupouns"
          element={
            <SellerProtectedRoute>
              <ShopAllCoupouns />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-withdraw-money"
          element={
            <SellerProtectedRoute>
              <ShopWithDrawMoneyPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-deposit-money"
          element={
            <SellerProtectedRoute>
              <ShopDepositMoneyPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-messages"
          element={
            <SellerProtectedRoute>
              <ShopInboxPage />
            </SellerProtectedRoute>
          }
        />
        {/* Admin Routes */}
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/action/:id"
          element={
            <ProtectedAdminRoute>
              <AdminActionPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-users"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardUsers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-sellers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardSellers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-orders"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardOrders />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-products"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardProducts />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-tasks"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardTasks />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-transfers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardTransfer />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-events"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardEvents />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-withdraw-request"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardWithdraw />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-withdraw-request-workers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardWithdrawUser />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-newcodes"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardNewCode />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-usedcodes"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardUsedCode />
            </ProtectedAdminRoute>
          }
        />
        {/* Code Vendor Routes */}
        <Route
          path="/codevendor/dashboard"
          element={
            <ProtectedCodeVendorRoute>
              <CodeVendorDashboardPage />
            </ProtectedCodeVendorRoute>
          }
        />
        <Route
          path="/codevendor-newcodes"
          element={
            <ProtectedCodeVendorRoute>
              <CodeVendorDashboardNewCodes />
            </ProtectedCodeVendorRoute>
          }
        />
        <Route
          path="/codevendor-usedcodes"
          element={
            <ProtectedCodeVendorRoute>
              <CodeVendorDashboardUsedCodes />
            </ProtectedCodeVendorRoute>
          }
        />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;

// Pystack API test Public Key: pk_test_ce3becdbd917d77292665073fccbc6de12dfa5b8
// Paystack API test Secrete Key: sk_test_197dcdd88d4e5d1126132ade81f082148b981d49 

// live PK - pk_live_32cba88ca2dcc9c45269a49559787aa1a6a55964

// Stripe Secrete Text Key: sk_test_51KrQjzDp9xWz4VfZgzzW2xWz8IJZiILIVU75XXth4zHUqCl3Vpw5wK179kVN1kYSXUC610UlekKn87RMiw2h7ptB00WVVHne4y
// Stripe API test Key: pk_test_51KrQjzDp9xWz4VfZmZMn6yayM6OFy96Y1ZXX4G0QhmrOwBVPOoaJeNAABTMpVuXKEBSaxd7h0Xy7qV7WbmBdDrGW00zQ4iMdMj


/*



<Route path="/app.tst-token/login-miner" element={<LoginMinerPage />} />
        <Route path="/app.tst-token/signup-miner" element={<SignUpMinerPage />} />
        <Route path="/miner/sup/:telegramID" element={<SignUpMinerRefPage />} />

        <Route 
        path="/app.tst-token/miner/earn" 
        element={
        //<ProtectedMinerRoute>
          <MinerAccountPage />
        //</ProtectedMinerRoute>
        } 
        />

        <Route path="/app.tst-token/miner/friends" element={<MinerFriendsPage />} />
        <Route path="/app.tst-token/miner/tasks" element={<MinerTasksPage />} />
        <Route path="/app.tst-token/miner/boost" element={<MinerBoostsPage />} />
        <Route path="/app.tst-token/miner/stats" element={<MinerStatsPage />} />
        <Route path="/app.tst-token/miner/league" element={<MinerLeaguePage />} />
        <Route path="/app.tst-token/minertask/:id" element={<MinerTaskDetailPage />} />
        <Route path="/app.tst-token/white-paper" element={<MineWhitePaperPage />} />

*/