import React, { useEffect } from "react";
import { AiOutlineLogin, AiOutlineMessage } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineShoppingBag, HiBell } from "react-icons/hi";
import { FaArrowsDownToPeople } from "react-icons/fa6";
import { CiMoneyBill, CiMemoPad } from "react-icons/ci";
import {
  MdOutlineAdminPanelSettings,
 // MdOutlinePassword,
  MdOutlineTrackChanges,
  MdSettings,
} from "react-icons/md";
import { TbAddressBook, TbReportMoney } from "react-icons/tb";
import { FaLink } from "react-icons/fa6";
import { TiVendorAndroid } from "react-icons/ti";
import { FaTasks, FaJoget } from "react-icons/fa";
import { RxPerson } from "react-icons/rx";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import seting from "../../Assests/images/settings.gif";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

const ProfileSidebar = ({ setActive, active }) => {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  const navigate = useNavigate();
  const {user} = useSelector((state) => state.user);

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])


  const logoutHandler = () => {
    axios
      .get(`${server}/user/logout`)  // No need for `withCredentials` since we're using JWT
      .then((res) => {
        // Remove the JWT token from localStorage
        localStorage.removeItem('user_Token');
  
        // Optionally, reset the user state in Redux if you're using Redux
        // dispatch({ type: 'UserLogout' });
  
        // Display success message
        toast.success(res.data.message);
  
        // Redirect to login page
        navigate("/login");
  
        // Reload the page if necessary
        window.location.reload(true);
      })
      .catch((error) => {
        console.error(error.response ? error.response.data.message : 'Logout failed');
      });
  };

  
  const logoutHandler2 = () => {
    axios
      .get(`${server}/user/logout2`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/login");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };



  
  return (
    <div className="w-full bg-[#1c1c1c] shadow-sm rounded-[10px] p-4 pt-8 text-[#fff]">
      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(1)}
      >
        <div className="block">
        <RxPerson size={20} color={active === 1 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 1 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Profile
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 1 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          {t('Profile')}
        </span>
      </div>
      
      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(2)}
      >
        <div className="block">
        <HiOutlineShoppingBag size={20} color={active === 2 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 2 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Orders
        </span>
        </div>

        <span
          className={`pl-3 ${
            active === 2 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          {t('Orders')}
        </span>
      </div>

      {
        user && user?.role === "Worker" && (
          <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(3)}
      >
        <div className="block">
        <FaTasks size={20} color={active === 3 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 3 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Tasks
        </span>
        </div>

        <span
          className={`pl-3 ${
            active === 3 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          {t('Tasks_List')}
        </span>
      </div>
        )}
        

        {
        user && user?.role === "Worker" && (
          <div
              className="flex items-center cursor-pointer w-full mb-8"
              onClick={() => setActive(4)}
            >
             
        <div className="block">
        <FaArrowsDownToPeople size={20} color={active === 4 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 4 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Projects
        </span>
        </div>
              <span
                className={`pl-3 ${
                  active === 4 ? "text-[#c89b2a]" : ""
                } 800px:block hidden`}
              >
                {t('Projects')}
              </span>
            </div>
        )
      }

      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(5) || navigate("/inbox")}
      >
        <div className="block">
        <AiOutlineMessage size={20} color={active === 5 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 5 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Inbox
        </span>
        </div>

        <span
          className={`pl-3 ${
            active === 5 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          {t('Inbox')}
        </span>
      </div>

      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(6)}
      >
        <div className="block">
        <MdOutlineTrackChanges size={20} color={active === 6 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 6 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Track
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 6 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
         {t('Track_Order')}
        </span>
      </div>


      {user && user?.googleSignup !== true ? (
        <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(7)}
      >
        <div className="block">
        <RiLockPasswordLine size={20} color={active === 7 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 7 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          PW
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 7 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          {t('Change_Password')}
        </span>
      </div>
      ) : (
        <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(70)}
      >
        <div className="block">
        <RiLockPasswordLine size={20} color={active === 70 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 70 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          CPin
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 70 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          Change Pin
        </span>
      </div>
      )}
      

      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(8)}
      >
        <div className="block">
        <TbAddressBook size={20} color={active === 8 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 8 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Add
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 8 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          {t('Address')}
        </span>
      </div>

      <div
              className="flex items-center cursor-pointer w-full mb-8"
              onClick={() => setActive(9)}
            >
        <div className="block">
        <TbReportMoney size={20} color={active === 9 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 9 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Funds
        </span>
        </div>
              <span
                className={`pl-3 ${
                  active === 9 ? "text-[#c89b2a]" : ""
                } 800px:block hidden`}
              >
                {t('Earnings')}
              </span>
            </div>
      

      {user && user?.role === "Admin" && (
        <Link to="/admin/dashboard">
          <div
            className="flex items-center cursor-pointer w-full mb-8"
            onClick={() => setActive(10)}
          >
        <div className="block">
        <MdOutlineAdminPanelSettings size={20} color={active === 10 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 10 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Admin
        </span>
        </div>
            <span
              className={`pl-3 ${
                active === 10 ? "text-[#c89b2a]" : ""
              } 800px:block hidden`}
            >
              {t('Admin_Dashboard')}
            </span>
          </div>
        </Link>
      )}

      {user && user?.role === "codeVendor" && (
        <Link to="/codevendor/dashboard">
          <div
            className="flex items-center cursor-pointer w-full mb-8"
            onClick={() => setActive(11)}
          >
        <div className="block">
        <TiVendorAndroid size={20} color={active === 11 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 11 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Vendor
        </span>
        </div>
            <span
              className={`pl-3 ${
                active === 11 ? "text-[#c89b2a]" : ""
              } 800px:block hidden`}
            >
              {t('Code_Vendor_Dashboard')}
            </span>
          </div>
        </Link>
      )}

      {user && user?.role === "Worker" && (
        <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(12)}
      >
        <div className="block">
        <FaLink size={20} color={active === 12 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 12 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          APLinks
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 12 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          {t('APLinks')}
        </span>
      </div>
      )}

      {user && user?.role === "Worker" && (
        <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(13)}
      >
        <div className="block">
        <RiMoneyDollarBoxFill size={20} color={active === 13 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 13 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
         Sales
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 13 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          {t('Sales')}
        </span>
      </div>
      )}

    <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(14)}
      >
        <div className="block">
        <MdSettings size={20} color={active === 14 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 14 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Set
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 14 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          {t('Edit_Profile')}
        </span>
      </div>

      {user && user?.staffRole === "Content Writer" && (
        <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(15)}
      >
        <div className="block">
        <FaJoget size={20} color={active === 15 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 15 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Posts
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 15 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          Articles
        </span>
      </div>
      )}

    {user && user?.staffRole !== "noneStaff" && (
        <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(16)}
      >
        <div className="block">
        <HiBell size={20} color={active === 16 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 16 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Jobs
        </span>
        </div>

        <span
          className={`pl-3 ${
            active === 16 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          Direct Jobs
        </span>
      </div>
      )}

      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(17)}
      >
        <div className="block">
        <CiMoneyBill size={20} color={active === 17 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 17 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Invest
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 17 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          Investment
        </span>
      </div>

      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(18)}
      >
        <div className="block">
        <CiMemoPad size={20} color={active === 18 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 18 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >Packs
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 18 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          Subscriptions
        </span>
      </div>
     
      <div
        className="single_item flex items-center cursor-pointer w-full mb-8"
        //onClick={user && user.googleSignup === true ? logoutHandler : logoutHandler2}
        onClick={logoutHandler}
      >
       <div className="block">
        <AiOutlineLogin size={20} color={active === 19 ? "#c89b2a" : ""} />

        <span
          className={`${
            active === 19 ? "text-[#c89b2a]" : ""
          } 800px:hidden text-[8px]`}
        >
          Logout
        </span>
        </div>
        <span
          className={`pl-3 ${
            active === 19 ? "text-[#c89b2a]" : ""
          } 800px:block hidden`}
        >
          {t('Log_out')}
        </span>
      </div>
    </div>
  );
};

export default ProfileSidebar;
