import React, { useEffect } from "react";

import tfacebook from '../../Assests/logos/tfacebook.png';
import tgoogle from '../../Assests/logos/tgoogle.png';
import tmicrosoft from '../../Assests/logos/tmicrosoft.png';
import tlinkedin from '../../Assests/logos/tlinkedin.png';
import instagram from '../../Assests/logos/instagram.png';
import blendTech from '../../Assests/logos/blendTech.png';
import googleNew from '../../Assests/logos/googleNew.png';

import youtube from '../../Assests/logos/youtube.png';
import spotify from '../../Assests/logos/spotify.png';

import boomplay from '../../Assests/logos/boomplay.png';
import linkedin from '../../Assests/logos/linkedin.png';
import tiktok from '../../Assests/logos/tiktok.png';
import whatsapp from '../../Assests/logos/whatsapp.png';

import soundcloud from '../../Assests/logos/soundcloud.png';
import twitter from '../../Assests/logos/twitter.png';
import telegram from '../../Assests/logos/telegram.png';


import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const TrustedBy = () => {

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

    
  
  return (
    <div
      className={`relative min-h-[30vh] 800px:min-h-[20vh] w-full bg-no-repeat`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
      }}
    >
        <br />
        <br />
        <h2 className="text-[25px] font-[500] text-center items-center justify-center">Trusted By:</h2>
        <div className="w-full items-center content-center justify-center">
        <div className="px-10 py-2 justify-center w-full flex">
        <img
            src={tgoogle}
            width='150px'
            alt="facebook"
            className="800px:mr-6"
          />
          <img
            src={tfacebook}
            width='150px'
            alt="facebook"
            className="800px:mr-6"
            />
            <img
            src={blendTech}
            width='150px'
            alt="facebook"
            />
         </div>

        <div className="px-10 py-2 justify-center w-full flex">
       <img
            src={tmicrosoft}
            width='150px'
            alt="facebook"
            className="800px:mr-6"
          />
          <img
            src={tlinkedin}
            width='150px'
            alt="facebook"
            className="800px:mr-6"
            />
            <img
            src={instagram}
            width='150px'
            alt="facebook"
            />
        </div>
        </div>
      </div>
  );
};

export default TrustedBy;
