import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const ShippingTerms = () => {
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize mt-4`}
        >
          Terms of Service 
        </h1>
        <h5 className="text-[18px] font-[500]">Last Updated: January 2, 2025</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        Welcome to Totlesoft! By accessing or using our website, platform, or services, you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our platform.
        </p>
        <br />
        <h5 className="text-[18px] font-[500]">1. About Totlesoft</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        Totlesoft is a digital platform that connects businesses with independent professionals and agencies worldwide. We leverage cutting-edge technology to assist companies and business owners in promoting their products. Our platform serves as a global online marketplace where individuals can buy, sell, and collaborate remotely. 
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        We offer innovative solutions to companies and businesses by breaking down large tasks into smaller, manageable portions, delegating them to multiple workers, and providing efficient and effective results. Totlesoft specializes in deploying tasks to workers in various fields including graphics, software development, product testing, data collection and analysis, moderation and/or extraction of data, annotation, categorization, image or video tagging, translation, transcription, research, surveys, and more.</p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        Totlesoft envisions a workplace not confined by walls, but one that thrives on creativity, innovation, and boundless opportunities. We are building the world's largest digital platform to help anyone grow in business. Join our workforce and start earning today!
        </p>
        <br />

        <h5 className="text-[18px] font-[500]">2. Acceptance of Terms</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        By accessing or using Totlesoft (the "Platform"), you acknowledge and agree to abide by these Terms of Service, as well as our Privacy Policy and any other terms and policies referenced herein. You agree that these Terms apply to your use of the Platform, including its services, content, and features. 
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">3. User Responsibilities</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        * Eligibility: You must be at least 18 years old to use our services, unless otherwise prohibited by applicable law.
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]"> 
          * Registration: To access certain features of the Platform, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process.       
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]"> 
          * Account Security: You are responsible for maintaining the confidentiality of your account information and for all activities under your account. You agree to notify us immediately if you suspect any unauthorized use of your account.       
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">4. Platform Use</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        Totlesoft connects businesses and professionals remotely, allowing users to buy, sell, and collaborate on projects. You may use the Platform for various activities, such as:<br />
        * Collaborating with businesses and professionals.<br />
        * Purchasing services or tasks.<br />
        * Offering freelance services and tasks.<br />
        * Posting or applying for job opportunities.<br />
        All activities on the Platform must comply with applicable laws and regulations.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">5. Payment and Fees</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        * Service Fees: Totlesoft charges a fee for services provided on the platform. The specific fee structure will be outlined for each transaction.<br />
        * Payments: Payment for services provided through the Platform must be made through our supported payment methods. All payments are processed in accordance with our Payment Terms, which may be updated from time to time.<br />
        * Taxes: You are responsible for paying any taxes associated with your use of the Platform, including sales taxes or other applicable fees.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">6. Content Ownership and Usage</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        * User Content: You retain ownership of any content you upload or submit to the Platform, including work samples, communications, and other materials. By uploading or submitting content, you grant Totlesoft a license to use, display, and distribute that content for the purposes of providing the services on the Platform.<br />
        * Platform Content: All content provided by Totlesoft, including but not limited to logos, branding, text, software, and design, remains the property of Totlesoft and is protected by copyright and other intellectual property laws.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">7. Privacy and Data Use</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        We value your privacy and handle your personal data in accordance with our Privacy Policy. By using the Platform, you consent to the collection, use, and sharing of your information as described in our Privacy Policy.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">8. Termination of Account</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        Totlesoft reserves the right to suspend or terminate your account at any time, with or without cause, if we believe you have violated these Terms or if we are required to do so by law. You may also terminate your account at any time by contacting us through the provided support channels.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">9. Prohibited Activities</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        You agree not to use the Platform to:<br />
        * Engage in fraudulent or deceptive activities. <br />
        * Violate intellectual property rights. <br />
        * Upload, post, or distribute harmful, abusive, or inappropriate content. <br />
        * Engage in activities that violate any local, state, national, or international laws.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">10. Disclaimers and Limitations of Liability</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        * No Warranty: The Platform and its services are provided "as is" and without warranty of any kind, either express or implied.<br />
        * Limitation of Liability: Totlesoft shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Platform.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">11. Indemnification</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        You agree to indemnify, defend, and hold harmless Totlesoft, its affiliates, employees, and partners from any claims, damages, losses, liabilities, and expenses (including legal fees) arising from your use of the Platform, violation of these Terms, or infringement of third-party rights.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">12. Modifications to the Terms</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        Totlesoft reserves the right to modify these Terms of Service at any time. When changes are made, we will update the "Last Updated" date at the top of this document. By continuing to use the Platform after such changes, you agree to the revised Terms.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">13. Governing Law and Dispute Resolution</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        These Terms shall be governed by and construed in accordance with the laws of our operational Country/Region. Any disputes arising out of or related to these Terms will be resolved through binding arbitration, and you agree to submit to the exclusive jurisdiction of the courts in our operational City/Region.
        </p>

        <br />
        <h5 className="text-[18px] font-[500]">14. Contact Information</h5>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        * If you have any questions about these Terms of Service, please contact us at:<br />
        * Email: info@totlesoft.com<br />
        * Website: https://totlesoft.com
        </p>

        
        <Link to="/" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[14px]">
                    Home page
                 </span>
            </div><br /><br />
        </Link>
      </div>
    </div>
  );
};

const ShippingPage = () => {
    return (
      <div>
        <Header activeHeading={6} />
        <ShippingTerms />
        <Footer />
      </div>
    );
  };

export default ShippingPage;
