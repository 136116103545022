import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineLinkedin,
  AiFillGithub,
  AiFillProfile,
} from "react-icons/ai";

const TalentCardGigs = ({ data }) => {

  const [openUserProfile, setOpenUserProfile] = useState(false);
   
  return (
    <>
    <div className="w-full h-[450px] bg-[#F6F6F5] rounded-lg shadow-sm p-3 relative border-b-4 border-[#c89b2a]">
       <h4 className="pb-3 font-[500] text-[16px]">
       {data.name.length > 100 ? data.name.slice(0, 100) + "..." : data.name}
          </h4>

          <h4 className="pt-2 text-[16px] text-[#000000ba] leading whitespace-pre-line" dangerouslySetInnerHTML={{ __html: data?.description.slice(0, 200) + "..."}}>
          </h4>

          {data.subscriptionAmount === 0 ? null : (
            <h5 className={`${styles.productDiscountPrice} !text-[13px] mt-4`}>
            <small className="font-[400] text-[13px] text-[blue]">Monthly Subscription:</small> ${data.subscriptionAmount}
              </h5>
          )}


          {data.charge === 0 ? null : (
            <h5 className={`${styles.productDiscountPrice} !text-[13px]`}>
            <small className="font-[400] text-[13px] text-[blue]">Charge: from</small> ${data.charge}
              </h5>
          )}

          
            
           <div className="flex">
        <Link to={`/talent/${data._id}`}>
        <div className={`${styles.button} !w-[70px] !h-[30px] mb-5`}>
                 <span className="text-[#fff] font-[Poppins] text-[14px]">
                    Details
                 </span>
            </div>
        </Link>

        <div className={`${styles.button} !w-[70px] !h-[30px] mb-5 ml-2`}
        onClick={() => setOpenUserProfile(true)}
        >
                 <span className="text-[#fff] font-[Poppins] text-[14px]">
                    Profile
                 </span>
        </div>

        </div>  

        <div className='mt-2 flex'>
        <img className='mr-2 w-[50px] h-[50px] rounded-full object-cover border-[3px] border-[#fff]' alt='Article cover' src={data?.user.avatar.url}></img>
        <div>
        <h5 className='text-[13px] text-[blue]'>{data?.user.name}</h5>
        <h5 className='text-[12px]'>{data?.user.specialization}</h5>
        {data.subscriptionAmount === 0 ? null : (
          <h5 className='text-[12px] text-[blue]'>{data?.totalSubscribers} Subscribers</h5>
        )}
        
        </div>
        </div> 


        {openUserProfile &&  (
          
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                 <div className={`w-[90%] 800px:w-[50%] bg-[#012] text-white shadow rounded h-[unset] min-h-[50vh] px-6 py-4`}>
            <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenUserProfile(false)}
                className="cursor-pointer"
                color="#fff"
              />
            </div>

            <div className="block">
          <h5 className="text-[15px] text-[#c89b2a]">Spotlight:</h5>
            <h5 className="text-[15px] text-[#fff]">{data?.user?.spotLight}</h5>
          </div>
          <br />

            <div className="flex w-full">
            <div className="relative">
              <img
                src={`${data?.user?.avatar?.url}`}
                className="w-[100px] h-[100px] rounded-full object-cover border-[3px] border-[#fff] mt-5"
                alt=""
              />
            </div>
          </div>
          <br />  

          <div className="flex">
            <h5 className="text-[15px] text-[#c89b2a]">Name:</h5>
            <h5 className="text-[15px] text-[#fff] ml-2">{data?.user?.name}</h5>
          </div>     

          <div className="flex">
            <h5 className="text-[15px] text-[#c89b2a]">Location:</h5>
            <h5 className="text-[15px] text-[#fff] ml-2">{data?.user?.country}</h5>
          </div> 

          <div className="flex">
            <h5 className="text-[15px] text-[#c89b2a]">Strength:</h5>
            <h5 className="text-[15px] text-[#fff] ml-2">{data?.user?.specialization}</h5>
          </div>    

          <div className="flex">
            <h5 className="text-[15px] text-[#c89b2a]">E-mail:</h5>
            <h5 className="text-[15px] text-[#fff] ml-2">{data?.userEmail}</h5>
          </div> 

          {data?.subscriptionAmount === 0 ? null : (
            <div className="flex">
            <h5 className="text-[15px] text-[#c89b2a]">Subscription:</h5>
            <h5 className="text-[15px] text-[#fff] ml-2">${data?.subscriptionAmount} Monthly</h5>
          </div> 
          )}

          {data?.charge === 0 ? null : (
            <div className="flex">
            <h5 className="text-[15px] text-[#c89b2a]">Full Package:</h5>
            <h5 className="text-[15px] text-[#fff] ml-2">${data?.charge}</h5>
          </div> 
          )}           

          <div className="flex items-center mt-[15px]">
           {data?.user?.facebookUserName === null ? <Link to="#"> <AiFillFacebook size={20} className="cursor-pointer" /></Link> : <Link to={`https://www.facebook.com/${data?.user?.facebookUserName}`} target="_blank"> <AiFillFacebook size={20} className="cursor-pointer" /></Link>}

           {data?.user?.instagramUserName === null ? <Link to="#"> <AiFillInstagram size={20} style={{ marginLeft: "15px", cursor: "pointer" }} /></Link> : <Link to={`https://instagram.com/${data?.user?.instagramUserName}`} target="_blank"> <AiFillInstagram size={20} style={{ marginLeft: "15px", cursor: "pointer" }} /></Link>}

           {data?.user?.youtubeUserName === null ? <Link to="#"> <AiFillYoutube size={20} style={{ marginLeft: "15px", cursor: "pointer" }} /></Link> : <Link to={`https://www.youtube.com/@${data?.user?.youtubeUserName}`} target="_blank"> <AiFillYoutube size={20} style={{ marginLeft: "15px", cursor: "pointer" }} /></Link>}

           {data?.user?.linkedinURL === null ? <Link to="#"> <AiOutlineLinkedin size={20} style={{ marginLeft: "15px", cursor: "pointer" }} /></Link> : <Link to={data?.user?.linkedinURL} target="_blank"> <AiOutlineLinkedin size={20} style={{ marginLeft: "15px", cursor: "pointer" }} /></Link>}

           {data?.user?.githubURL === null ? <Link to="#"> <AiFillGithub size={20} style={{ marginLeft: "15px", cursor: "pointer" }} /></Link> : <Link to={data?.user?.githubURL} target="_blank"> <AiFillGithub size={20} style={{ marginLeft: "15px", cursor: "pointer" }} /></Link>}

           {data?.user?.potfolioURL === null ? <Link to="#"> <AiFillProfile size={20} style={{ marginLeft: "15px", cursor: "pointer" }} /></Link> : <Link to={data?.user?.potfolioURL} target="_blank"> <AiFillProfile size={20} style={{ marginLeft: "15px", cursor: "pointer" }} /></Link>}
           
           </div>
           <br />
           <h5 className="text-[15px] text-[#fff]">Follow / Connect</h5>          
                      
          <div>
          <br />
          </div>       
         </div>
         </div>
        )}      
        
      </div>
    </>
  );
};

export default TalentCardGigs;
