import React, { useEffect, useState } from 'react';
import STaskCard2Header from '../components/Route/STaskCard/STaskCard2Header';
import STaskCard2 from '../components/Route/STaskCard/STaskCard2';
//import data from '../components/data/data.json';
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import { useSelector } from "react-redux";
import { RiCloseLine } from "react-icons/ri";

function STasksPage2() {

  const [filterValue, setFilterValue] = useState(new Set())
  const {allSTasks} = useSelector((state) => state.stasks);
  const { user } = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  const taskByCountry = allSTasks && allSTasks.filter((itee) => itee.targetedCountry === user?.country || itee.targetedCountry === "Worldwide");

  useEffect(() => {
    if (taskByCountry) {
      setData(taskByCountry);
    } else {
      setData([]);
    }
  }, [taskByCountry]);
  
  function filterFunction(data, filterVal) {
    return data?.filter((i) => filterVal.includes(i.category)) || [];
  }
  
  const filterData = filterFunction(data, Array.from(filterValue));
  
  return (
    <div>
      <div className="w-full bg-[#F6F6F5] min-h-screen">
        <Header />
        <STaskCard2Header />
        {user && user?.role === "Worker" ? (
          <>
            {filterData.length > 0 && (
              <div className="md:max-w-5xl md:mx-auto p-4 m-3 rounded -mt-16 relative z-10 bg-white">
                <div className="w-4/5">
                  {Array.from(filterValue).map((val, index) => (
                    <span key={index} className="inline-flex items-center m-2">
                      <button
                        key={Math.random()}
                        className="bg-[#d9dddc] text-sm p-2 text-cyan cursor-pointer font-semibold rounded rounded-r-none"
                      >
                        {val}
                      </button>
                      <button
                        onClick={() => {
                          filterValue.delete(val);
                          setFilterValue((prev) => new Set([...prev]));
                        }}
                        className="bg-cyan focus:outline-none p-2 text-cyan cursor-pointer rounded rounded-l-none"
                      >
                        <RiCloseLine
                          size="1.2rem"
                          color="white"
                          style={{ pointerEvents: "none" }}
                        />
                      </button>
                    </span>
                  ))}
                  <div className="absolute right-7 top-7 z-10 bg-white">
                    <button
                      onClick={() => setFilterValue(new Set())}
                      className="text-cyan focus:outline-none cursor-pointer border-b-2 font-semibold border-cyan"
                    >
                      Clear All
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="p-3">
              {(filterData?.length > 0 ? filterData : data || []).map(
                (i, index) => (
                  <STaskCard2
                    key={index}
                    data={i}
                    filterFunction={(e) =>
                      setFilterValue((prev) =>
                        new Set([e.target.textContent, ...prev])
                      )
                    }
                  />
                )
              )}
            </div>
            {data?.length === 0 && (
              <h1 className="text-center w-full pb-[100px] text-[20px]">
                No project found!
              </h1>
            )}
          </>
        ) : (
          <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
            Access Denied! Only Workers access the route.
          </h1>
        )}
        <br />
        <br />
        <br />
        <Footer />
      </div>
    </div>
  );
}
       
export default STasksPage2;