import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const VideoTaskCard = ({ data }) => {
   
  
  return (
    <>
    <Link to={`/videotask/${data._id}`}>
      <div className="w-full h-[500px] bg-[#F6F6F5] rounded-lg shadow-sm p-3 relative cursor-pointer">
       <h4 className="pb-3 font-[500] text-[16px]">
       {data.name.length > 20 ? data.name.slice(0, 20) + "..." : data.name}
          </h4>         

          <img
            src={`${data.images && data.images[0]?.url}`}
            alt="code vendor"
            className="w-full object-contain py-3"
          />

          <h4 className="pb-3 font-[400]">
          {data.description.length > 60 ? data.description.slice(0, 60) + "..." : data.description}
          </h4>

         <div className="py-2 flex items-center justify-between">
            <div className="flex">
              <h5 className={`${styles.productDiscountPrice}`}>
              <small className="font-[400] text-[10px] text-[blue]">Payment:</small> TST{data.taskPayment}
              </h5>
              
            </div>
            <span className="font-[400] text-[15px] text-[blue] mb-10px">
              {data.totalTaskCompleted}/{data.taskQuantity} tasks
            </span>
          </div>
        

        <Link to={`/videotask/${data._id}`}>
        <div className={`${styles.button} mb-5`}>
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Watch
                 </span>
            </div>
        </Link>
        
      </div>
      </Link>
    </>
  );
};

export default VideoTaskCard;
