import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { createExtProduct } from "../../redux/actions/extproduct";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import styles from "../../styles/styles";
import Spinner from "../Spinner/Spinner";

const CreateExtProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [videoSupportId, setVideoSupportId] = useState("");
  const [extvideoSupportURL, setExtvideoSupportURL] = useState("");
  const [checkoutURL, setCheckoutURL] = useState("");
  const [category, setCategory] = useState("");
  const [productType, setProductType] = useState("");
  const [productClass, setProductClass] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      navigate("/dashboard"); // Avoid reloading the page; allow React Router to handle navigation
    }
  }, [error, success, navigate]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    const extproductData = {
      name,
      description,
      videoSupportId,
      extvideoSupportURL,
      checkoutURL,
      category,
      productType,
      productClass,
      tags,
      originalPrice,
      discountPrice,
      shopId: seller._id,
      images, // Assuming images are already converted to base64 strings
    };
  
    dispatch(createExtProduct(extproductData));
    setIsLoading(false);
  };

  return (
    <>
    {seller && seller?.role === "ProSeller" ? (
      <>
      {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">List External Product</h5>
      {/* create product form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your product name..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your product description..."
          ></textarea>
        </div>

        <br />
        <div>
          <label className="pb-2">
           Youtube Video ID:
          </label>
          <input
            type="text"
            name="videoSupportId"
            value={videoSupportId}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setVideoSupportId(e.target.value)}
            placeholder="Enter Youtube Video ID..."
          />
        </div>

        <br />
        <div>
          <label className="pb-2">
           External Video URL:
          </label>
          <input
            type="text"
            name="extvideoSupportURL"
            value={extvideoSupportURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setExtvideoSupportURL(e.target.value)}
            placeholder="Enter Video URL..."
          />
        </div>

        <br />
        <div>
          <label className="pb-2">
           Checkout URL:
          </label>
          <input
            type="text"
            name="checkoutURL"
            value={checkoutURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setCheckoutURL(e.target.value)}
            placeholder="Enter Checkout URL..."
          />
        </div>
       
        <br />
        <div>
          <label className="pb-2">
            Category <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="Choose a category">Choose a category</option>
            {categoriesData &&
              categoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>

        <div className="w-full pb-2 mt-3">
                    <div className=" w-[100%]">
                      <label className="block pt-2">Product Type</label>
                      <div className="mt-1">
                        <select
                        name="productType"
                        onChange={(e) => setProductType(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="Digital Product">Digital Product</option>
                        <option value="Physical Product">Physical Product</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />

                  <div className="w-full pb-2 mt-3">
                    <div className=" w-[100%]">
                      <label className="block pt-2">Product Class</label>
                      <div className="mt-1">
                        <select
                        name="productClass"
                        onChange={(e) => setProductClass(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="Internal">Internal</option>
                        <option value="External">External</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />
       
        <div>
          <label className="pb-2">Tags</label>
          <input
            type="text"
            name="tags"
            value={tags}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTags(e.target.value)}
            placeholder="Enter your product tags..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">Original Price</label>
          <input
            type="number"
            name="price"
            value={originalPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder="Enter your product price..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="price"
            value={discountPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDiscountPrice(e.target.value)}
            placeholder="Enter your product price with discount..."
          />
        </div>
        
        <br />
        <div>
          <label className="pb-2">
            Upload Images <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={i}
                  key={i}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div>
          <br />
          <div>
            <input
              type="submit"
              value="Create"
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <br />

          <div className={`${styles.noramlFlex} w-full`}>
              <h4>Having error <span className="text-[red]">'entity too large'?</span> please use Totlesoft Image Compressor to compress your picture to less than 40kb before re-uploading it</h4>
              </div>
              <div>
                <Link to="/image-compressor">
                <button
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Compress Image
              </button>
                </Link>
            </div>
        </div>
      </form>
    </div>
    )}
      </>
    ) : (
      <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
      Access Denied! Only ProSellers Can List ExtProducts.
      </h1>
    )}
    
    </>
    
  );
};

export default CreateExtProduct;
