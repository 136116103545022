import React, { useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import ViewApplicantsCard from "../components/STasks/ViewApplicantsCard";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import styles from "../styles/styles";
import { server } from "../server";
import { toast } from "react-toastify";
import axios from "axios";
import { RxCross1 } from "react-icons/rx";


const ViewApplicantsPage = () => {
  const { allSTasks } = useSelector((state) => state.stasks);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const { seller } = useSelector((state) => state.seller);
  const [workStatus, setWorkStatus] = useState('');
  const [open, setOpen] = useState(false);
  
  

  useEffect(() => {
      const data = allSTasks && allSTasks.find((i) => i._id === id);
      setData(data);
   
  }, [allSTasks]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .put(`${server}/stask/work-on-project-status/${data._id}`,{
        workStatus: workStatus,
      },
      {withCredentials: true})
      .then((res) => {
       toast.success("Work Status Updated!");
       window.location.reload();
       });
  };
  
  return (
    <div>
      <Header activeHeading={3} />
      <br />
      <br />
      {
        seller && seller?.accountStatus === "active" ? (
          <div className={`${styles.section}`}>
            <Link to="/dashboard-s-tasks" className={`${styles.button} mb-5`}
          >
            <span className="text-[#fff] font-[Poppins] !text-[14px] flex items-center py-[10px] px-[10px] text-xl/4">
                Back 
            </span>
          </Link>
          <br />
          
          <div className={`${styles.button} !bg-[blue] mb-5`}
          onClick={() => setOpen(true)}
          >
            <span className="text-[#fff] font-[Poppins] !text-[14px] flex items-center py-[10px] px-[10px] text-xl/4">
                Project Status 
            </span>
          </div>
          <h5 className="text-[blue]">Current Status: {data && data.workStatus}</h5>
          <h5>Kindly update project status to let workers know when the project has 'Started' or 'Completed'.</h5>

          {open && (
            <div className="w-full fixed h-screen top-0 left-0 bg-[#00000031] z-[9999] flex items-center justify-center">
            <div className="w-[50%] min-h-[40vh] bg-white rounded shadow p-4">
            <div className="flex justify-end w-full">
              <RxCross1 size={25} onClick={() => setOpen(false)} />
            </div>
             <h1 className="text-[18px] font-Poppins-600 px-3">
                Update Project Status
              </h1>
              <br />
              <select
                name="workStatus"
                id="workStatus"
                onChange={(e) => setWorkStatus(e.target.value)}
                className="w-[120px] h-[35px] border rounded px-2 mx-2"
              >
                <option value="-">-</option>
                <option value="Not Started">Not Started</option>
                <option value="Started">Started</option>
                <option value="Completed">Completed</option>
              </select>
              <button
                type="submit"
                className={`block ${styles.button} text-white !h-[42px] mt-4 text-[18px]`}
                onClick={handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
          )}

          <br />
            <h1 className="mt-2 text-[25px] font-[600]">Proposals:
            
            </h1><br />
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {data && data?.applicants.map((i, index) => <ViewApplicantsCard data={i} id={data._id} key={index} />)}
        </div>
        {data && data.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            No Proposal Submited!
          </h1>
        ) : null}
      </div>
        ) : (
          <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
            Access Denied! Only Workers can perform task.
          </h1>
          )
      }
      <br />
      <br />
      </div>
  );
};

export default ViewApplicantsPage;
